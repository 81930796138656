import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useLocation, useHistory } from 'react-router-dom';
import _ from 'lodash';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useApp } from 'context/app';
import { api } from 'services/api';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

const useStyles = makeStyles(theme => ({
  paper: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  }, 
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function ResetPassword(props) {
  const { t } = useTranslation();
  const [ password, setPassword ] = useState("");
  const [ confirmPassword, setConfirmPassword ]  = useState("");
  const [ passwordError, setPasswordError ] = useState("");
  const [ confirmPasswordError, setConfirmPasswordError ] = useState("");
  const [ invalidToken, setInvalidToken ] = useState(false);
  const [ user, setUser ] = useState({});
  const { enqueueSnackbar } = useApp();
  const location = useLocation();
  const history = useHistory();

  const referer = _.get(props, 'location.state.referer', '/app');
  const classes = useStyles();

  useEffect(() => {
    async function verifyToken() {
      const result = await api.get(`/user/verify-reset-token/${token}`);
      if (result.data.error) {
        setInvalidToken(true);
        return false;
      }
      setUser(result.data);
    };
    const token = props.match.params.token;
    if (token) {
      verifyToken();
    }
  }, [props.match.params.token]);

  const setLink = (path) => {
    if (location.pathname !== path) {
      history.push(path);
    }
  };

  const onSave = async (e) => {
    e.preventDefault();
    let error = false;

    if (!password) {
      setPasswordError(t('Default.requiredField'));
      error = true;
    }

    if (password.length < 6 && !passwordError) {
      setPasswordError(t('Default.passwordMinChars', { value: 6 }));
      error = true;
    }

    if (!confirmPassword) {
      setConfirmPasswordError(t('Default.requiredField'));
      error = true;
    }

    if (password !== confirmPassword && !confirmPasswordError) {
      setConfirmPasswordError(t('Default.samePasswordConfirm'));
      error = true;
    }

    if (error) {
      return false;
    }

    const result = await api.post(`/user/change-password-with-token`, {
      token: props.match.params.token,
      userId: user.id,
      password,
    });

    if (result.data.error) {
      enqueueSnackbar(result.data.error, {
        variant: 'error',
      });
      return false;
    }

    enqueueSnackbar(t('ResetPassword.passwordChanged'), {
      variant: 'success',
    });
    setLink('/login');
  };


  if (invalidToken) {
    return <Redirect to={referer} />;
  }

  return (
    <React.Fragment>
      <Grid container alignItems="center" justify="center">
        <Grid item xs={12} lg={4}>
          <Paper className={classes.paper}>
            <Container component="main" maxWidth="xs">
              <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                  Alteração de senha
                </Typography>
                <form className={classes.form} noValidate>
                  <TextField
                    error={!!passwordError}
                    helperText={passwordError}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label={t('ResetPassword.passwordInput')}
                    type="password"
                    id="password"
                    value={password}
                    onChange={e => {
                      setPasswordError("");
                      setPassword(e.target.value);
                    }}
                  />
                  <TextField
                    error={!!confirmPasswordError}
                    helperText={confirmPasswordError}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="confirm-password"
                    label={t('ResetPassword.passwordConfirmInput')}
                    type="password"
                    id="confirm-password"
                    value={confirmPassword}
                    onChange={e => {
                      setConfirmPasswordError("");
                      setConfirmPassword(e.target.value);
                    }}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    disableElevation
                    color="inherit"
                    size="large"
                    className={classes.submit}
                    onClick={onSave}
                  >
                    {t('ResetPassword.changePasswordButton')}
                  </Button>
                </form>
              </div>
            </Container>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default ResetPassword;
