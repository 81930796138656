import React from 'react';
import { useTranslation } from 'react-i18next';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useAuth } from 'context/auth';

const useStyles = makeStyles(theme => ({
  paper: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  paragraph: {
    marginBottom: 20,
  },
}));

function NotFound(props) {
  const { t } = useTranslation();
  const { authToken } = useAuth();
  const classes = useStyles();
  return (
    <Grid container alignItems="center" justify="center" align="center">
      <Grid item xs={12}>
        <Container component="main" maxWidth="md" justify="center">
          <div className={classes.paper}>
            <Typography variant="h2" className={classes.paragraph}>
              {t('NotFound.pageNotFound')}
            </Typography>
            <Typography variant="h5" className={classes.paragraph}>
              {t('NotFound.pageNotFound2')}
            </Typography>
            <Typography variant="h5" className={classes.paragraph}>
              <Link href={authToken ? '/settings' : '/signup'}>{t('NotFound.notFoundLink')}</Link>
            </Typography>
          </div>
        </Container>
      </Grid>
    </Grid>
  );
}

export default NotFound;