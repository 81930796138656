/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import moment from "moment";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useAuth } from "context/auth";
import { useApp } from "context/app";
import { api } from "services/api";
import { validateUrl } from "utils";
import RemoveLink from "pages/Modal/RemoveLink";
import Image from "react-render-image";
import Skeleton from "@material-ui/lab/Skeleton";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

import AddIcon from "@material-ui/icons/Add";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import InstagramIcon from "@material-ui/icons/Instagram";
import MenuIcon from "@material-ui/icons/Menu";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import previewPhone from "assets/images/img-preview-phone.png";
import ScrollContainer from "react-indiana-drag-scroll";

const useStyles = makeStyles((theme) => ({
  imagePreview: {
    width: "345px",
    marginTop: theme.spacing(2),
    height: "565px",
    backgroundRepeat: "no-repeat",
    position: "relative",
    backgroundSize: "contain",
    backgroundImage: `url(${previewPhone})`,
    backgroundPosition: "center",
    top: "-5px",
  },
  phoneContent: {
    position: "absolute",
    top: "135px",
    bottom: "60px",
    left: "45px",
    right: "45px",
    overflow: "hidden",
    border: "2px solid #dce4ec",
    background: "#fafafa",
  },
  phoneContentLoader: {
    position: "absolute",
    top: "135px",
    bottom: "60px",
    left: "45px",
    right: "45px",
    overflow: "hidden",
    border: "2px solid #dce4ec",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    zIndex: 1,
    "&::after": {
      content: '""',
      display: "block",
      position: "absolute",
      left: "44%",
      top: "40%",
      width: "40px",
      height: "40px",
      borderStyle: "solid",
      borderColor: "#CCC",
      borderTopColor: "transparent",
      borderWidth: "4px",
      borderRadius: "50%",
      "-webkit-animation": "spin .8s linear infinite",
      animation: "spin .8s linear infinite",
    },
  },
  phoneContentError: {
    position: "absolute",
    top: "135px",
    bottom: "60px",
    left: "45px",
    right: "45px",
    overflow: "hidden",
    border: "2px solid #dce4ec",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    zIndex: 1,
  },
  phoneContentHeader: {
    position: "absolute",
    top: "60px",
    left: "45px",
    right: "45px",
    overflow: "hidden",
    border: "2px solid #dce4ec",
    background: "#fafafa",
    borderBottom: "none",
  },
  phoneContentFooter: {
    position: "absolute",
    left: "45px",
    right: "45px",
    bottom: "60px",
    overflow: "hidden",
    border: "2px solid #dce4ec",
    borderTop: "none",
    background: "#fafafa",
  },
  phoneHeaderAvatar: {
    padding: "10px",
  },
  phoneAvatar: {
    width: "50px",
    height: "50px",
    borderRadius: "50px",
    float: "left",
  },
  phonePost: {
    width: "100%",
    display: "block",
  },
  paperAddEditLink: {
    boxShadow:
      "0px 2px 1px -1px rgba(220,228,236,1), 0px 1px 1px 0px rgba(220,228,236,1), 0px 1px 3px 0px rgba(220,228,236,1)",
  },
  addEditLink: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    flex: "1",
    height: "535px",
  },
  section1: {
    margin: theme.spacing(4, 2, 2, 2),
  },
  section2: {
    margin: theme.spacing(2, 2, 2, 2),
  },
  section3: {
    margin: theme.spacing(2, 2, 3, 2),
  },
  gridList: {
    width: "auto",
    marginBottom: "0px !important",
    marginLeft: "0px !important",
    marginRight: "0px !important",
    marginTop: "-10px !important",
  },
  gridItem: {
    padding: "1px !important",
  },
  imageLink: {
    marginTop: 10,
    marginBottom: 20,
    width: "auto",
    height: 220,
    backgroundSize: "cover",
  },
  arrow: {
    color: "#333",
  },
  tooltip: {
    backgroundColor: "#333",
    fontSize: 12,
  },
}));

const StyledGridListTileBar = withStyles({
  root: {
    background: "transparent",
  },
  titleWrap: {
    marginLeft: "5px !important",
    marginTop: "10px",
  },
})(GridListTileBar);

function Dashboard() {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const scrollRef = useRef(null);
  const addEditLinkDivRef = useRef(null);
  const { loginData, setLoginData } = useAuth();
  const { enqueueSnackbar } = useApp();
  const [posts, setPosts] = useState([]);
  const [menus, setMenus] = useState([]);
  const [selectedPost, setSelectedPost] = useState(null);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [selectedUser, setSelectedUser] = useState({});
  const [cursor, setCursor] = useState(null);
  const [removeLinkDialog, setRemoveLinkDialog] = useState(false);
  const [fetchingPosts, setFetchingPosts] = useState(false);
  const [fetchingPostsError, setFetchingPostsError] = useState("");
  const [fetchingMenus, setFetchingMenus] = useState(false);
  const [linkError, setLinkError] = useState("");
  const [menuLinkError, setMenuLinkError] = useState("");
  const [menuTextError, setMenuTextError] = useState("");
  const lgUp = useMediaQuery(theme.breakpoints.up("lg"));

  useEffect(() => {
    const selected =
      loginData.accounts.find((l) => l.selected) ||
      _.get(loginData, "accounts[0]");
    if (selected) {
      setSelectedUser(selected);
      if (selectedPost && selectedPost.ig_id !== selected.instagramId) {
        setSelectedPost(null);
      }
    }
  }, [loginData]);

  useEffect(() => {
    if (selectedUser._id) {
      if (selectedUser.accountType === "post") {
        setCursor(null);
        setPosts([]);
        setFetchingPostsError(false);
        setFetchingPosts(true);
      } else {
        setMenus([]);
        setPosts([]);
        setFetchingPostsError(false);
        setFetchingMenus(true);
      }
    }
  }, [selectedUser._id, selectedUser.accountType]);

  useEffect(() => {
    async function fetchMenus() {
      const url = `/account/${selectedUser._id}/menus`;
      const res = await api.get(url, {
        headers: { "X-FullScreenLoader": false },
      });
      const data = res.data;

      if (data.error) {
        enqueueSnackbar(data.error, {
          variant: "error",
        });
        setFetchingMenus(false);
        return false;
      }
      setMenus(data);
      setFetchingMenus(false);
    }
    if (fetchingMenus === true) {
      fetchMenus();
    }
  }, [fetchingMenus]);

  useEffect(() => {
    async function fetchPosts() {
      let url = `/account/${selectedUser._id}/posts`;
      if (cursor) {
        url += `/${cursor}`;
      }
      const res = await api.get(url, {
        headers: { "X-FullScreenLoader": false },
      });
      const data = res.data;

      if (data.error) {
        enqueueSnackbar(data.error, {
          variant: "error",
        });
        setFetchingPosts(false);
        setFetchingPostsError(data.error);
        return false;
      }

      const newCursor = _.get(data, "media.data.paging.cursors.after");
      const newPosts = _.get(data, "media.data");
      const allPosts = _.union(posts, newPosts);

      setCursor(newCursor);
      setPosts(allPosts);
      setFetchingPosts(false);
      setFetchingPostsError("");
    }
    if (fetchingPosts === true) {
      fetchPosts();
    }
  }, [fetchingPosts]);

  const onEndScroll = (x, y) => {
    if (
      y + scrollRef.current.container.current.offsetHeight >=
      scrollRef.current.container.current.scrollHeight
    ) {
      if (cursor) {
        setFetchingPosts(true);
      }
    }
  };

  const getSelectedButtonColor = () => {
    let menuColor = selectedUser.menuColor
      ? selectedUser.menuColor
      : { r: 51, g: 51, b: 51, a: 1 };
    if (!selectedUser.outlinedMenu && selectedUser.backgroundColor) {
      return `rgba(${selectedUser.backgroundColor.r}, ${selectedUser.backgroundColor.g}, ${selectedUser.backgroundColor.b}, ${selectedUser.backgroundColor.a})`;
    } else if (selectedUser.outlinedMenu) {
      return `rgba(${menuColor.r}, ${menuColor.g}, ${menuColor.b}, ${menuColor.a})`;
    }
    return "";
  };

  const StyledButton = withStyles(() => {
    if (selectedUser.outlinedMenu) {
      let menuColor = selectedUser.menuColor
        ? selectedUser.menuColor
        : { r: 51, g: 51, b: 51, a: 1 };
      return {
        root: {
          "&:hover": {
            backgroundColor: `rgba(${menuColor.r}, ${menuColor.g}, ${menuColor.b}, 0.08)`,
          },
        },
      };
    }
  })(Button);

  const handleAccountType = async (event, type) => {
    if (selectedUser.accountType === type) {
      return false;
    }
    setSelectedUser({
      ...selectedUser,
      accountType: type,
    });

    const result = await api.put(
      `/account/${selectedUser._id}`,
      {
        accountType: type,
      },
      { headers: { "X-FullScreenLoader": false } }
    );

    if (result.data.error) {
      enqueueSnackbar(result.data.error, {
        variant: "error",
      });
      return false;
    }

    if (result.status === 200 && result.data) {
      const cp = loginData;
      const index = cp.accounts.findIndex((l) => l._id === selectedUser._id);
      cp.accounts[index] = result.data;
      setLoginData(Object.assign({}, cp));
    }
  };

  const removeLink = () => {
    if (selectedPost !== null) {
      removePostLink();
    } else if (selectedMenu !== null) {
      removeMenuLink();
    }
  };

  const removeMenuLink = async () => {
    const result = await api.delete(`/link-menu/${selectedMenu.id}`, {
      data: { userAccountId: selectedUser._id },
    });

    if (result.data.error) {
      enqueueSnackbar(result.data.error, {
        variant: "error",
      });
      return false;
    }

    const cp = menus;
    const index = cp.findIndex((pp) => pp.id === selectedMenu.id);
    cp.splice(index, 1);
    setSelectedMenu(null);
    setMenus(cp);
    setRemoveLinkDialog(false);

    enqueueSnackbar(t("Dashboard.linkRemoved"), {
      variant: "info",
    });
    return false;
  };

  function getShortCode(url) {
    const shortCode = url.split("/p/")[1];

    //remove bars from string
    return shortCode?.split("/")[0] || "";
  }

  const removePostLink = async () => {
    const result = await api.delete(`/link/${selectedPost.id}`, {
      data: { userAccountId: selectedUser._id },
    });

    if (result.data.error) {
      enqueueSnackbar(result.data.error, {
        variant: "error",
      });
      return false;
    }

    const cp = posts;
    const index = cp.findIndex((pp) => pp.id === selectedPost.id);
    cp[index] = {
      ...cp[index],
      id: null,
      link: null,
    };
    setSelectedPost(null);
    setPosts(cp);
    setRemoveLinkDialog(false);

    enqueueSnackbar(t("Dashboard.linkRemoved"), {
      variant: "info",
    });
  };

  const saveLink = async () => {
    if (!selectedPost.link) {
      setLinkError(t("Default.requiredField"));
      return false;
    }
    if (!validateUrl(selectedPost.link)) {
      setLinkError(t("Default.invalidLink"));
      return false;
    }

    const result = selectedPost.id
      ? await api.put(`/link/${selectedPost.id}`, {
          link: selectedPost.link,
          userAccountId: selectedUser._id,
        })
      : await api.post("/link", {
          instagramPostId: selectedPost.instagramPostId,
          instagramShortcode: getShortCode(selectedPost?.permalink),
          instagramImgUrl: selectedPost.media_url,
          link: selectedPost.link,
          userAccountId: selectedUser._id,
        });

    if (result.data.error) {
      enqueueSnackbar(result.data.error, {
        variant: "error",
      });
      return false;
    }

    if (result.status === 200 && result.data) {
      const linkedPost = result.data;
      const cp = posts;
      const index = cp.findIndex((pp) => pp.id === linkedPost.instagramPostId);
      cp[index] = {
        ...cp[index],
        ...linkedPost,
        id: linkedPost._id,
      };
      setSelectedPost(cp[index]);
      setPosts(_.cloneDeep(cp));
      enqueueSnackbar(
        selectedPost.id ? t("Dashboard.linkUpdated") : t("Dashboard.linkAdded"),
        {
          variant: "success",
        }
      );
    }
  };

  const saveMenuLink = async () => {
    let error = false;
    if (!selectedMenu.text) {
      setMenuTextError(t("Default.requiredField"));
      error = true;
    }
    if (selectedMenu.text.length > 20) {
      setMenuTextError(t("Default.maxChars", { value: 20 }));
      error = true;
    }
    if (!selectedMenu.link) {
      setMenuLinkError(t("Default.requiredField"));
      error = true;
    }
    if (!validateUrl(selectedMenu.link)) {
      setMenuLinkError(t("Default.invalidLink"));
      error = true;
    }
    if (error) {
      return false;
    }

    const result = selectedMenu.id
      ? await api.put(`/link-menu/${selectedMenu.id}`, {
          text: selectedMenu.text,
          link: selectedMenu.link,
          userAccountId: selectedUser._id,
        })
      : await api.post("/link-menu", {
          text: selectedMenu.text,
          link: selectedMenu.link,
          userAccountId: selectedUser._id,
        });

    if (result.data.error) {
      enqueueSnackbar(result.data.error, {
        variant: "error",
      });
      return false;
    }

    const linkedMenu = result.data;
    const cp = menus;
    const index = cp.findIndex((pp) => pp.id === linkedMenu.id);
    if (index > -1) {
      cp[index] = {
        ...cp[index],
        ...linkedMenu,
        id: linkedMenu._id,
      };
    } else {
      cp.push(linkedMenu);
    }
    setSelectedMenu(index > -1 ? cp[index] : linkedMenu);
    setMenus(_.cloneDeep(cp));
    enqueueSnackbar(
      selectedMenu.id ? t("Dashboard.linkUpdated") : t("Dashboard.linkAdded"),
      {
        variant: "success",
      }
    );
  };

  return (
    <Grid container>
      <Grid item xs={12} align="center">
        <Box style={{ height: 56, marginTop: "-20px" }}>
          <ToggleButtonGroup
            value={selectedUser.accountType}
            exclusive
            onChange={handleAccountType}
            size="small"
          >
            <ToggleButton value="post" style={{ height: 27 }}>
              <InstagramIcon fontSize="small" />
              {"   "}
              <Typography style={{ fontSize: 12, marginLeft: 5 }}>
                {t("Dashboard.posts")}
              </Typography>
            </ToggleButton>
            <ToggleButton value="menu" style={{ height: 27 }}>
              <Typography style={{ fontSize: 12, marginRight: 5 }}>
                {t("Dashboard.menus")}
              </Typography>
              {"   "}
              <MenuIcon fontSize="small" />
            </ToggleButton>
          </ToggleButtonGroup>
          {selectedUser.accountType === "post" ? (
            <Typography
              style={{ marginTop: 8, fontSize: lgUp ? 13 : 11 }}
              variant="body2"
            >
              {t("Dashboard.typePostDescription")}
            </Typography>
          ) : (
            <Typography
              style={{ marginTop: 8, fontSize: lgUp ? 13 : 11 }}
              variant="body2"
            >
              {t("Dashboard.typeMenuDescription")}
            </Typography>
          )}
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12} lg={5} align="center">
            <div className={classes.imagePreview}>
              <div className={classes.phoneContentHeader}>
                <Grid container>
                  <Grid item xs={12} style={{ padding: "10px" }}>
                    <Grid container>
                      <Grid item xs={2}>
                        <img
                          className={classes.phoneAvatar}
                          src={selectedUser.avatar}
                          alt={"@" + selectedUser.instagramUsername}
                          title={"@" + selectedUser.instagramUsername}
                        />
                      </Grid>
                      <Grid item xs={10} align="left">
                        <p
                          style={{
                            marginTop: "10px",
                            marginLeft: "20px",
                            overflow: "hidden",
                          }}
                        >
                          linkna.bio
                          <br />
                          <strong>@{selectedUser.instagramUsername}</strong>
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
              {fetchingPosts === true ? (
                <div className={classes.phoneContentLoader}></div>
              ) : null}
              {!!fetchingPostsError === true ? (
                <div
                  className={classes.phoneContentError}
                  style={{ display: "flex" }}
                >
                  <Grid container alignItems="center">
                    <Grid item xs={12}>
                      <Typography variant="h5" style={{ color: "white" }}>
                        {fetchingPostsError}
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              ) : null}
              {selectedUser.accountType === "post" ? (
                <ScrollContainer
                  ref={scrollRef}
                  onScroll={onEndScroll}
                  className={classes.phoneContent}
                  nativeMobileScroll={false}
                  style={{ cursor: "pointer" }}
                >
                  <Grid container>
                    <Grid item xs={12}>
                      <GridList
                        cellHeight={115}
                        className={classes.gridList}
                        cols={3}
                      >
                        {posts.map((p, i) => (
                          <GridListTile
                            onClick={() => {
                              setSelectedPost(p);
                              addEditLinkDivRef.current.scrollIntoView({
                                behavior: "smooth",
                                block: "nearest",
                              });
                            }}
                            key={i}
                            cols={1}
                            className={classes.gridItem}
                          >
                            <Image
                              src={p.media_url}
                              loaded={
                                <div style={{ backgroundColor: "#333" }}>
                                  <img
                                    style={{
                                      opacity: p.link ? 1 : 0.5,
                                      width: 115,
                                    }}
                                    src={p.media_url}
                                    alt={i}
                                  />
                                </div>
                              }
                              loading={
                                <Skeleton
                                  animation="wave"
                                  variant="rect"
                                  width={115}
                                  height={115}
                                />
                              }
                            />
                            {p.id ? (
                              <StyledGridListTileBar
                                align="left"
                                titlePosition="bottom"
                                title={
                                  <Chip
                                    style={{ cursor: "pointer" }}
                                    color="secondary"
                                    size="small"
                                    label={t("Dashboard.link")}
                                  />
                                }
                              />
                            ) : null}
                          </GridListTile>
                        ))}
                      </GridList>
                    </Grid>
                  </Grid>
                </ScrollContainer>
              ) : null}
              {selectedUser.accountType === "menu" ? (
                <React.Fragment>
                  <ScrollContainer
                    style={{
                      bottom: "118px",
                      borderBottom: "none",
                      padding: 30,
                      paddingBottom: 0,
                      backgroundImage:
                        selectedUser.gradient === true
                          ? `linear-gradient(
                      transparent,
                      rgba(
                        ${_.get(selectedUser, "backgroundEndColor.r", 255)},
                        ${_.get(selectedUser, "backgroundEndColor.g", 255)},
                        ${_.get(selectedUser, "backgroundEndColor.b", 255)},
                        ${_.get(selectedUser, "backgroundEndColor.a", 1)})
                    )`
                          : "none",
                      backgroundColor: selectedUser.backgroundColor
                        ? `rgba(${selectedUser.backgroundColor.r}, ${selectedUser.backgroundColor.g}, ${selectedUser.backgroundColor.b}, ${selectedUser.backgroundColor.a})`
                        : "",
                    }}
                    className={classes.phoneContent}
                    nativeMobileScroll={false}
                  >
                    <Grid container>
                      {menus
                        .sort(
                          (a, b) =>
                            new Date(b.createdAt) - new Date(a.createdAt)
                        )
                        .map((menu, index) => {
                          let menuColor = selectedUser.menuColor
                            ? selectedUser.menuColor
                            : { r: 51, g: 51, b: 51, a: 1 };
                          return (
                            <Grid key={index} item xs={12}>
                              <StyledButton
                                style={{
                                  marginBottom: 10,
                                  overflow: "hidden",
                                  backgroundColor: !selectedUser.outlinedMenu
                                    ? `rgba(${menuColor.r}, ${menuColor.g}, ${menuColor.b}, ${menuColor.a})`
                                    : "",
                                  color: getSelectedButtonColor(),
                                  borderColor: selectedUser.outlinedMenu
                                    ? `rgba(${menuColor.r}, ${menuColor.g}, ${menuColor.b}, ${menuColor.a})`
                                    : "",
                                }}
                                disableElevation
                                variant={
                                  selectedUser.outlinedMenu
                                    ? "outlined"
                                    : "contained"
                                }
                                color="primary"
                                onClick={() => {
                                  setSelectedMenu(menu);
                                  addEditLinkDivRef.current.scrollIntoView({
                                    behavior: "smooth",
                                    block: "nearest",
                                  });
                                }}
                                fullWidth
                              >
                                {menu.text}
                              </StyledButton>
                            </Grid>
                          );
                        })}
                    </Grid>
                  </ScrollContainer>
                  <div
                    className={classes.phoneContentFooter}
                    style={{
                      backgroundImage:
                        selectedUser.gradient === true
                          ? `linear-gradient(
                        rgba(
                          ${_.get(selectedUser, "backgroundEndColor.r", 255)},
                          ${_.get(selectedUser, "backgroundEndColor.g", 255)},
                          ${_.get(selectedUser, "backgroundEndColor.b", 255)},
                          ${_.get(selectedUser, "backgroundEndColor.a", 1)}),
                        rgba(
                          ${_.get(selectedUser, "backgroundEndColor.r", 255)},
                          ${_.get(selectedUser, "backgroundEndColor.g", 255)},
                          ${_.get(selectedUser, "backgroundEndColor.b", 255)},
                          ${_.get(selectedUser, "backgroundEndColor.a", 1)})
                      )`
                          : "none",
                      backgroundColor: selectedUser.backgroundColor
                        ? `rgba(${selectedUser.backgroundColor.r}, ${selectedUser.backgroundColor.g}, ${selectedUser.backgroundColor.b}, ${selectedUser.backgroundColor.a})`
                        : "",
                    }}
                  >
                    <Grid container align="center">
                      <Grid
                        item
                        xs={12}
                        style={{
                          paddingLeft: 30,
                          paddingRight: 30,
                          paddingBottom: 10,
                          paddingTop: 10,
                        }}
                      >
                        <Button
                          variant="contained"
                          disableElevation
                          fullWidth
                          className={classes.button}
                          startIcon={<AddIcon />}
                          onClick={() => {
                            setSelectedMenu({});
                            addEditLinkDivRef.current.scrollIntoView({
                              behavior: "smooth",
                              block: "nearest",
                            });
                          }}
                        >
                          {t("Dashboard.addButton")}
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                </React.Fragment>
              ) : null}
            </div>
          </Grid>
          <Grid item lg={1}></Grid>
          <Grid
            item
            xs={12}
            lg={5}
            ref={addEditLinkDivRef}
            style={{ marginBottom: 20 }}
          >
            {selectedUser.accountType === "post" ? (
              <Paper className={classes.paperAddEditLink}>
                {selectedPost === null ? (
                  <Grid
                    container
                    alignItems="center"
                    justify="center"
                    className={classes.addEditLink}
                  >
                    <Container style={{ textAlign: "center", padding: 0 }}>
                      <PhotoCameraIcon
                        color="primary"
                        style={{ fontSize: 100 }}
                      />
                      <Typography
                        variant="subtitle1"
                        style={{ fontSize: "1rem" }}
                      >
                        {t("Dashboard.selectPostDescription")}
                      </Typography>
                    </Container>
                  </Grid>
                ) : (
                  <Grid className={classes.addEditLink}>
                    <Grid item xs={12}>
                      <Container>
                        <div className={classes.section1}>
                          <Typography variant="h6">
                            {selectedPost.id
                              ? t("Dashboard.editLinkTitle")
                              : t("Dashboard.addLinkTitle")}
                          </Typography>
                          <Typography variant="subtitle2">
                            {t("Dashboard.postedAt", {
                              value: moment.unix(
                                selectedPost.taken_at_timestamp
                              ),
                            })}
                          </Typography>
                          <Image
                            src={selectedPost.media_url}
                            loading={
                              <Skeleton
                                animation="wave"
                                variant="rect"
                                width={"auto"}
                                height={220}
                                style={{ marginTop: 10, marginBottom: 20 }}
                              />
                            }
                            loaded={
                              <div
                                className={classes.imageLink}
                                style={{
                                  backgroundImage: `url(${selectedPost.media_url})`,
                                }}
                              />
                            }
                          />
                          <TextField
                            error={!!linkError}
                            helperText={linkError}
                            variant="outlined"
                            id="link"
                            label={t("Dashboard.linkInput")}
                            value={selectedPost.link || ""}
                            onChange={(e) => {
                              setLinkError("");
                              setSelectedPost({
                                ...selectedPost,
                                link: e.target.value,
                              });
                            }}
                            fullWidth
                          />
                        </div>
                        {selectedPost.id ? (
                          <React.Fragment>
                            <Divider variant="middle" />
                            <div className={classes.section2}>
                              <Grid container>
                                <Grid item xs={6}>
                                  <Typography variant="body1">
                                    {t("Dashboard.linkClicks")}
                                  </Typography>
                                </Grid>
                                <Grid item xs={6} align="right">
                                  <Typography variant="body1">
                                    <strong>
                                      {_.get(selectedPost, "clicks", 0)}
                                    </strong>
                                  </Typography>
                                </Grid>
                              </Grid>
                            </div>
                            <Divider variant="middle" />
                          </React.Fragment>
                        ) : null}
                        <div className={classes.section3}>
                          <Grid container>
                            {selectedPost.id ? (
                              <Grid item xs={6}>
                                <Button
                                  variant="outlined"
                                  disableElevation
                                  onClick={() => setRemoveLinkDialog(true)}
                                >
                                  {t("Dashboard.removeLinkButton")}
                                </Button>
                              </Grid>
                            ) : null}
                            <Grid
                              item
                              xs={selectedPost.id ? 6 : 12}
                              align="right"
                            >
                              <Button
                                onClick={saveLink}
                                variant="contained"
                                color="primary"
                                fullWidth={selectedPost.id ? false : true}
                                disableElevation
                              >
                                {t("Dashboard.saveLinkButton")}
                              </Button>
                            </Grid>
                          </Grid>
                        </div>
                      </Container>
                    </Grid>
                  </Grid>
                )}
              </Paper>
            ) : null}
            {selectedUser.accountType === "menu" ? (
              <Paper className={classes.paperAddEditLink}>
                {selectedMenu === null ? (
                  <Grid
                    container
                    alignItems="center"
                    justify="center"
                    className={classes.addEditLink}
                  >
                    <Container style={{ textAlign: "center", padding: 0 }}>
                      <IconButton onClick={() => setSelectedMenu({})}>
                        <AddCircleIcon
                          color="primary"
                          style={{ fontSize: 100 }}
                        />
                      </IconButton>
                      <Typography
                        variant="subtitle1"
                        style={{ fontSize: "1rem" }}
                      >
                        {t("Dashboard.selectMenuDescription")}
                      </Typography>
                    </Container>
                  </Grid>
                ) : (
                  <Grid
                    container
                    justify="center"
                    className={classes.addEditLink}
                  >
                    <Grid item xs={12}>
                      <Container>
                        <div className={classes.section1}>
                          <Typography variant="h6">
                            {selectedMenu.id
                              ? t("Dashboard.editMenuTitle")
                              : t("Dashboard.addMenuTitle")}
                          </Typography>
                          {selectedMenu.id ? (
                            <Typography variant="subtitle2">
                              {t("Dashboard.createdAt", {
                                value: moment(selectedMenu.createdAt),
                              })}
                            </Typography>
                          ) : null}
                          <TextField
                            error={!!menuTextError}
                            helperText={menuTextError}
                            label={t("Dashboard.menuTextInput")}
                            value={selectedMenu.text || ""}
                            onChange={(e) => {
                              setMenuTextError("");
                              setSelectedMenu({
                                ...selectedMenu,
                                text: e.target.value,
                              });
                            }}
                            style={{ marginBottom: 10, marginTop: 10 }}
                            fullWidth
                            variant="outlined"
                          />
                          <TextField
                            error={!!menuLinkError}
                            helperText={menuLinkError}
                            label={t("Dashboard.menuLinkInput")}
                            value={selectedMenu.link || ""}
                            onChange={(e) => {
                              setMenuLinkError("");
                              setSelectedMenu({
                                ...selectedMenu,
                                link: e.target.value,
                              });
                            }}
                            fullWidth
                            style={{ marginBottom: 10, marginTop: 10 }}
                            variant="outlined"
                          />
                        </div>
                        {selectedMenu.id ? (
                          <React.Fragment>
                            <Divider variant="middle" />
                            <div className={classes.section2}>
                              <Grid container>
                                <Grid item xs={6}>
                                  <Typography variant="body1">
                                    {t("Dashboard.menuClicks")}
                                  </Typography>
                                </Grid>
                                <Grid item xs={6} align="right">
                                  <Typography variant="body1">
                                    <strong>
                                      {_.get(selectedMenu, "clicks", 0)}
                                    </strong>
                                  </Typography>
                                </Grid>
                              </Grid>
                            </div>
                            <Divider variant="middle" />
                          </React.Fragment>
                        ) : null}
                        <div className={classes.section3}>
                          <Grid container>
                            {selectedMenu.id ? (
                              <Grid item xs={6}>
                                <Button
                                  variant="outlined"
                                  disableElevation
                                  onClick={() => setRemoveLinkDialog(true)}
                                >
                                  {t("Dashboard.removeLinkButton")}
                                </Button>
                              </Grid>
                            ) : null}
                            <Grid
                              item
                              xs={selectedMenu.id ? 6 : 12}
                              align="right"
                            >
                              <Button
                                onClick={saveMenuLink}
                                variant="contained"
                                color="primary"
                                fullWidth={selectedMenu.id ? false : true}
                                disableElevation
                              >
                                {t("Dashboard.saveLinkButton")}
                              </Button>
                            </Grid>
                          </Grid>
                        </div>
                      </Container>
                    </Grid>
                  </Grid>
                )}
              </Paper>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
      <RemoveLink
        open={removeLinkDialog}
        onClose={() => setRemoveLinkDialog(false)}
        onSave={removeLink}
      />
    </Grid>
  );
}

export default Dashboard;
