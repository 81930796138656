import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import useMediaQuery from '@material-ui/core/useMediaQuery'

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

function ChangePassword(props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [ password, setPassword ] = useState("");
  const [ confirmPassword, setConfirmPassword ] = useState("");
  const [ passwordError, setPasswordError ] = useState("");
  const [ confirmPasswordError, setConfirmPasswordError ] = useState("");

  const onClick = (e) => {
    e.preventDefault();
    let error = false;

    if (!password) {
      setPasswordError(t('Default.requiredField'));
      error = true;
    }

    if (password.length < 6 && !passwordError) {
      setPasswordError(t('Default.Default.passwordMinChars', { value: 6 }));
      error = true;
    }

    if (!confirmPassword) {
      setConfirmPasswordError(t('Default.requiredField'));
      error = true;
    }

    if (password !== confirmPassword && !confirmPasswordError) {
      setConfirmPasswordError(t('Default.samePasswordConfirm'));
      error = true;
    }

    if (error) {
      return false;
    }

    props.onSave(password);
    onClose();
  };

  const onClose = () => {
    setPassword("");
    setConfirmPassword("");
    setPasswordError("");
    setConfirmPasswordError("");
    props.onClose();
  };

  return (
    <Dialog fullScreen={fullScreen} open={props.open} onClose={onClose}>
      <DialogTitle id="form-dialog-title">{t('Modal.ChangePassword.title')}</DialogTitle>
      <form onSubmit={e => onClick(e)} noValidate>
        <DialogContent>
          <DialogContentText>
            {t('Modal.ChangePassword.text')}
          </DialogContentText>
          <TextField
            error={!!passwordError}
            helperText={passwordError}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            autoFocus
            name="password"
            label={t('Modal.ChangePassword.passwordInput')}
            type="password"
            id="password"
            value={password}
            onChange={e => {
              setPasswordError("");
              setPassword(e.target.value);
            }}
          />
          <TextField
            error={!!confirmPasswordError}
            helperText={confirmPasswordError}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="confirm-password"
            label={t('Modal.ChangePassword.passwordConfirmInput')}
            type="password"
            id="confirm-password"
            value={confirmPassword}
            onChange={e => {
              setConfirmPasswordError("");
              setConfirmPassword(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="inherit">
            {t('Modal.ChangePassword.noButton')}
          </Button>
          <Button type="submit" onClick={onClick} color="inherit">
            {t('Modal.ChangePassword.yesButton')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default ChangePassword;
