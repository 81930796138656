/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import SelectRange from 'pages/Modal/SelectRange';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { addProtocol } from 'utils';
import { useApp } from 'context/app';
import { useAuth } from 'context/auth';
import { api } from 'services/api';

import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';

const useStyles = makeStyles(theme => ({
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

function Analytics() {
  const { t } = useTranslation();
    
  const pageViewsOptions = [{
    label: t('Analytics.today'),
    value: 'today',
  }, {
    label: t('Analytics.lastDays', { value: 7 }),
    value: '7d',
  }, {
    label: t('Analytics.lastDays', { value: 30 }),
    value: '30d',
  }, {
    label: t('Analytics.selectRange'),
    value: 'selectRange',
  }];

  const theme = useTheme();
  const { enqueueSnackbar } = useApp();
  const [ selectedUser, setSelectedUser ] = useState({});
  const [ links, setLinks ] = useState([]);
  const [ pageViews, setPageViews ] = useState([]);
  const [ totalView, setTotalView ] = useState(0);
  const [ periodTotalView, setPeriodTotalView ] = useState(0);
  const [ pageViewFilter, setPageViewFilter ] = useState(pageViewsOptions[1]);
  const [ pageViewFilterText, setPageViewFilterText ] = useState(pageViewsOptions[1].label);
  const [ page, setPage ] = React.useState(0);
  const [ rowsPerPage, setRowsPerPage ] = React.useState(10);
  const [ anchorEl, setAnchorEl ] = useState(null);
  const [ openSelectRange, setOpenSelectRange ] = useState(false);
  const { loginData } = useAuth();
  const classes = useStyles();
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    const selected = loginData.accounts.find(l => l.selected) || _.get(loginData, 'accounts[0]');
    if (selected) {
      setSelectedUser(selected);
    }
  }, [loginData]);

  useEffect(() => {
    async function callAsyncRequests() {
      await fetchAnalytics();
      await fetchAnalyticLinks();
    }
    if (selectedUser._id) {
      callAsyncRequests();
    }
  }, [selectedUser._id, selectedUser.accountType, pageViewFilter]);

  const fetchAnalyticLinks = async () => {
    if (selectedUser.accountType === 'post') {
      const url = `/link/all/${selectedUser._id}`;
      const res = await api.get(url);
      const data = res.data;

      if (data.error) {
        enqueueSnackbar(data.error, {
          variant: 'error',
        });
        return false;
      }
      setLinks(data);
    } else {
      const url = `/link-menu/all/${selectedUser._id}`;
      const res = await api.get(url);
      const data = res.data;

      if (data.error) {
        enqueueSnackbar(data.error, {
          variant: 'error',
        });
        return false;
      }
      setLinks(data);
    }
  };

  const fetchAnalytics = async () => {
    const timeZone = -new Date().getTimezoneOffset()/60;
    const url = `/account/${selectedUser._id}/analytics/${pageViewFilter.value}?timezone=${timeZone}`;
    const res = await api.get(url);
    const data = res.data;

    if (data.error) {
      enqueueSnackbar(data.error, {
        variant: 'error',
      });
      return false;
    }

    const formattedGroup = formatGroup(data.groups);

    setPageViews(formattedGroup);
    setTotalView(data.total);
    setPeriodTotalView(data.periodTotal);
  };

  const formatGroup = (groups) => {
    if (pageViewFilter.value !== 'today') {
      groups.forEach(g => {
        g.name = moment(g.name, 'YYYY-MM-DD').format(t('Default.shortDateFormat'));
      });
    }
    return groups;
  };

  const handleClick = event => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const setRange = (initialDate, finalDate) => {
    const label = moment(initialDate).format(t('Default.dateFormat')) + ' - ' + moment(finalDate).format(t('Default.dateFormat'));
    setPageViewFilter({
      label: label,
      value: 'selectRange/' + moment(initialDate).format("YYYY-MM-DD") + '/' + moment(finalDate).format("YYYY-MM-DD"),
    });
    setPageViewFilterText(label);
  };

  const handleSelectFilter = (opt) => {
    handleClose();
    if (opt.value === 'selectRange') {
      setOpenSelectRange(true);
    } else {
      setPageViewFilter(opt);
      setPageViewFilterText(opt.label);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12} md={6} style={{ marginBottom: 20 }}>
          <Container component="main">
            <Card>
              <CardHeader title={t('Analytics.totalViews')} subheader={t('Analytics.totalViewsDescription')} />
              <CardContent>
                <Typography variant="h5" component="p">{totalView}</Typography>
              </CardContent>
            </Card>
          </Container>
        </Grid>
        <Grid item xs={12} md={6} style={{ marginBottom: 20 }}>
          <Container component="main">
            <Card>
              <CardHeader title={t('Analytics.totalViewsRange')} subheader={pageViewFilterText} />
              <CardContent>
                <Typography variant="h5" component="p">{periodTotalView}</Typography>
              </CardContent>
            </Card>
          </Container>
        </Grid>
        <Grid item xs={12} style={{ marginBottom: 20 }}>
          <Container component="main">
            <Card>
              <CardHeader title={t('Analytics.pageViews') + ' - ' + pageViewFilterText} subheader={
                <Link color="inherit" href="#" onClick={handleClick}>{t('Analytics.changeRange')}</Link>
              } />
              <CardContent>
                <React.Fragment>
                  <ResponsiveContainer width="99%" aspect={mdUp ? 3 : 2}>
                    <AreaChart height={400} data={pageViews} margin={{
                        top: 10, left: -30, bottom: 0,
                      }}>
                      <CartesianGrid />
                      <XAxis dataKey="name" />
                      <YAxis dataKey="views" type="number" tickCount={4} allowDecimals={false} />
                      <Tooltip />
                      <Area type="monotone" name={t('Analytics.views')} dataKey="views" stroke={theme.palette.primary.main} fill={theme.palette.primary.light} />
                    </AreaChart>
                  </ResponsiveContainer>
                </React.Fragment>
              </CardContent>
            </Card>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}>
              {pageViewsOptions.map((opt, index) => (
                <MenuItem key={index} onClick={() => handleSelectFilter(opt)}>{opt.label}</MenuItem>
              ))}
            </Menu>
          </Container>
        </Grid>
        {links.length ? (
          <Grid item xs={12} style={{ marginBottom: 20 }}>
            <Container component="main">
              <Card>
                <CardHeader title={t('Analytics.yourLinks')} subheader={t('Analytics.yourLinksDescription')} />
                <CardContent>
                  <TableContainer className={classes.container}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell >
                            {t('Analytics.link')}
                          </TableCell>
                          <TableCell align="right">
                            {selectedUser.accountType === 'post' ? t('Analytics.image') : t('Analytics.buttonText')}
                          </TableCell>
                          <TableCell  align="right">
                            {t('Analytics.clicks')}
                          </TableCell>
                          <TableCell  align="right">
                            {t('Analytics.createdAt')}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {links.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((l, index) => {
                          return (
                            <TableRow hover key={index}>
                              <TableCell>
                                <Link color="inherit" href={addProtocol(l.link)}>
                                  {l.link}
                                </Link>
                              </TableCell>
                              <TableCell align="right" style={{ display: 'flex' }}>
                                {selectedUser.accountType === 'post' ? (
                                  <Avatar variant="rounded" className={classes.large} src={l.instagramImgUrl} />
                                ) : (
                                  l.text
                                )}
                              </TableCell>
                              <TableCell align="right">
                                {l.clicks}
                              </TableCell>
                              <TableCell align="right">
                                {moment(l.createdAt).format(t('Default.dateHourFormat'))}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[10, 20, 30]}
                    component="div"
                    count={links.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </CardContent>
              </Card>
            </Container>
          </Grid>
        ) : null}
      </Grid>
      <SelectRange
        onClose={e => setOpenSelectRange(false)}
        onSave={(newName) => setRange(newName)}
        open={openSelectRange}
      />
    </React.Fragment>
  );
}

export default Analytics;
