/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Route, Switch, useLocation, withRouter } from 'react-router-dom';
import queryString from 'query-string';

import AppBarLoggedIn from 'components/AppBarLoggedIn';
import AppBarLoggedOut from 'components/AppBarLoggedOut';
import AppBarRight from 'components/AppBarRight';
import Footer from 'components/Footer';
import { AuthContext } from 'context/auth';
import { AppContext } from 'context/app';
import Analytics from 'pages/Analytics';
import Dashboard from 'pages/Dashboard';
import Login from 'pages/Login';
import PrivateRoute from 'PrivateRoute';
import ResetPassword from 'pages/ResetPassword';
import Settings from 'pages/Settings';
import Support from 'pages/Support';
import Signup from 'pages/Signup';
import PublicAccountPage from 'pages/PublicAccountPage';

import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import { api } from 'services/api';
import { withTracker } from 'withTracker';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}));

function App(props) {
  const [ authToken, setAuthToken ] = useState();
  const [ loginData, setLoginData ] = useState();
  const [ loadedApp, setLoadedApp ] = useState(false);
  const classes = useStyles();
  const location = useLocation(); 

  const setToken = (data) => {
    if (typeof data === 'undefined') {
      localStorage.removeItem('token');
    } else {
      localStorage.setItem('token', JSON.stringify(data));
    }
    setAuthToken(data);
  };

  const getToken = () => localStorage.getItem('token');

  const setLogin = (data) => {
    if (typeof data === 'undefined') {
      localStorage.removeItem('login');
    } else {
      localStorage.setItem('login', JSON.stringify(data));
    }
    setLoginData(data);
  };

  const getLogin = () => JSON.parse(localStorage.getItem('login'));

  const getLoginServer = async () => {
    const login = getLogin();
    if (login && login.id) {
      const result = await api.get(`/user/${login.id}`);
      if (!result.data.error) {
        setLogin(result.data);
      } else {
        localStorage.removeItem('token');
        localStorage.removeItem('login');
        window.location.reload();
        return;
      }
    }
    setLoadedApp(true);
  };

  const verifyRef = () => {
    const parsed = queryString.parse(location.search);
    if (parsed && parsed.ref) {
      localStorage.setItem('ref', parsed.ref);
    }
  };

  useEffect(() => {
    setAuthToken(getToken());
    setLoginData(getLoginServer());
    verifyRef();
  }, []);

  return (!loadedApp ? null : 
    <AuthContext.Provider value={{
      authToken,
      setAuthToken: setToken,
      getToken,
      loginData,
      setLoginData: setLogin,
    }}>
      <AppContext.Provider value={{
        enqueueSnackbar: props.enqueueSnackbar,
        closeSnackbar: props.closeSnackbar
      }}>
        <Switch>
          <Route path={["/app", "/analytics", "/settings", "/support", "/login", "/signup", "/reset/:token"]}>
            <div className={classes.root}>
              <CssBaseline />
              { authToken ? <AppBarLoggedIn /> : <AppBarLoggedOut /> }
              <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container maxWidth="xl" className={classes.container}>
                  <Switch>
                    <PrivateRoute exact path="/app" component={Dashboard} loaded={loadedApp} />
                    <PrivateRoute exact path="/analytics" component={Analytics} loaded={loadedApp} />
                    <PrivateRoute exact path="/settings" component={Settings} loaded={loadedApp} />
                    <PrivateRoute exact path="/support" component={Support} loaded={loadedApp} />
                    <Route path="/login" exact component={Login} />
                    <Route path="/signup" exact component={Signup} />
                    <Route path="/reset/:token" component={ResetPassword} />
                  </Switch>
                  <Footer />
                </Container>
              </main>
              { authToken && location.pathname === '/app' ? <AppBarRight /> : null }
            </div>
          </Route>
          <Route path={["/:username"]}>
            <div className={classes.root}>
              <CssBaseline />
              <Route path="/:username" component={PublicAccountPage} />
            </div>
          </Route>
        </Switch>
      </AppContext.Provider>
    </AuthContext.Provider>
  );
}

export default withRouter(withSnackbar(withTracker(App)));