import React from 'react';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  title: {
    flexGrow: 1,
  },
  appBar: {
    boxShadow: 'none',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  }
}));

function AppBarLoggedOut() {

  const classes = useStyles();

  return (
    <React.Fragment>
      <AppBar className={classes.appBar} color="inherit" position="absolute">
        <Toolbar>
          <Typography color="inherit" variant="h6" className={classes.title}>
            linkna.bio
          </Typography>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}

export default AppBarLoggedOut;
