import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TwitterPicker } from 'react-color';
import Popover from '@material-ui/core/Popover';

const useStyles = makeStyles(theme => ({
  swatch: {
    padding: '5px',
    borderRadius: '1px',
    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
    display: 'inline-block',
    cursor: 'pointer',
  },
  color: {
    width: '26px',
    height: '14px',
  },
}));

function ColorPicker(props) {

  const classes = useStyles();
  const [ anchorEl, setAnchorEl ] = useState(null);
  const [ color, setColor ] = useState(props.color || {
    r: 0,
    g: 0,
    b: 0,
    a: 1,
  });

  const handleChange = (color) => {
    setColor(color.rgb);
    if (props.onChange) {
      props.onChange(color.rgb);
    }
  };

  const handleChangeComplete = (color) => {
    if (props.onChangeComplete) {
      props.onChangeComplete(color.rgb);
    }
  };

  const handlePopover = (e) => {
    if (e) {
      setAnchorEl(e.currentTarget);
    } else {
      setAnchorEl(null);
    }
  };

  const open = Boolean(anchorEl);

  return (
    <React.Fragment>
      <div className={classes.swatch} onClick={(e) => handlePopover(e)}>
        <div className={classes.color} style={{ background: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`}} />
      </div>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        disableRestoreFocus
        onClose={() => handlePopover(null)}
        anchorEl={anchorEl}>
        <TwitterPicker
          color={color}
          onChange={handleChange}
          onChangeComplete={handleChangeComplete}
          colors={props.colors || [
            '#333333', '#f5f5f5', '#FFFFFF', '#FAFAFA', '#f44336', '#ffcdd2', '#e91e63', '#f8bbd0',
            '#9c27b0', '#e1bee7', '#3f51b5', '#c5cae9', '#2196f3', '#bbdefb', '#00bcd4', '#b2ebf2',
            '#009688', '#b2dfdb', '#4caf50', '#c8e6c9', '#cddc39', '#f0f4c3', '#ffc107', '#ffecb3',
          ]}
        />
      </Popover>
    </React.Fragment>
  );
};

export default ColorPicker;