import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import useMediaQuery from '@material-ui/core/useMediaQuery'

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

function RenameInstagramAccount(props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const instagram = props['data-instagram'];

  const onClick = (e) => {
    e.preventDefault();
    props.onSave();
  };

  const onClose = () => {
    props.onClose();
  }

  return (
    <Dialog fullScreen={fullScreen} open={props.open} onClose={onClose}>
      <DialogTitle id="form-dialog-title">{t('Modal.RemoveAccount.title')}</DialogTitle>
      <form onSubmit={e => onClick(e)} noValidate>
        <DialogContent>
          <DialogContentText>
            {t('Modal.RemoveAccount.text', { value: `@${instagram.instagramUsername}` })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="inherit">
            {t('Modal.RemoveAccount.noButton')}
          </Button>
          <Button type="submit" onClick={onClick} color="inherit">
            {t('Modal.RemoveAccount.yesButton')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}


export default RenameInstagramAccount;
