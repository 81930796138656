import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import _ from 'lodash';

import { validateEmail } from 'utils';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Terms from 'pages/Modal/Terms';
import { useAuth } from 'context/auth';
import { useApp } from 'context/app';
import { api } from 'services/api';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';

const useStyles = makeStyles(theme => ({
  paper: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  labelCheckbox: {
    fontSize: '1rem',
  },
}));

function Signup(props) {
  const { t } = useTranslation();
  const [ instagram, setInstagram ] = useState("");
  const [ email, setEmail ] = useState("");
  const [ password, setPassword ] = useState("");
  const [ confirmPassword, setConfirmPassword ]  = useState("");
  const [ agree, setAgree ] = useState(false);
  const [ openTerms, setOpenTerms ] = useState(false);
  const { authToken, setAuthToken, setLoginData } = useAuth();
  const { enqueueSnackbar } = useApp();
  const referer = _.get(props, 'location.state.referer', '/app');
  const classes = useStyles();

  const [ instagramError, setInstagramError ] = useState("");
  const [ emailError, setEmailError ] = useState("");
  const [ passwordError, setPasswordError ] = useState("");
  const [ confirmPasswordError, setConfirmPasswordError ] = useState("");

  const openTermsModal = (e) => {
    e.preventDefault();
    setOpenTerms(true);
  };

  async function onSignup(e) {
    e.preventDefault();
    let error = false;

    if (!instagram) {
      setInstagramError(t('Default.requiredField'));
      error = true;
    }

    if (!email) {
      setEmailError(t('Default.requiredField'));
      error = true;
    }

    if (!validateEmail(email) && !emailError) {
      setEmailError(t('Default.invalidEmail'));
      error = true;
    }

    if (!password) {
      setPasswordError(t('Default.requiredField'));
      error = true;
    }

    if (password.length < 6 && !passwordError) {
      setPasswordError(t('Default.passwordMinChars', { value: 6 }));
      error = true;
    }

    if (!confirmPassword) {
      setConfirmPasswordError(t('Default.requiredField'));
      error = true;
    }

    if (password !== confirmPassword && !confirmPasswordError) {
      setConfirmPasswordError(t('Default.samePasswordConfirm'));
      error = true;
    }

    if (!agree && !error) {
      enqueueSnackbar(t('Signup.termsRequired'), {
        variant: 'error',
      });
      error = true;
    }

    if (error) {
      return false;
    }

    const instagramData = await api.get(`/user/instagram/${instagram.replace('@', '')}`);
    const user = _.get(instagramData, 'data.graphql.user');

    if (!user || !user.id) {
      enqueueSnackbar(t('Signup.instagramNotFound'), {
        variant: 'error',
      });
      return false;
    }

    if (user.is_private) {
      enqueueSnackbar(t('Signup.instagramPrivate'), {
        variant: 'error',
      });
      return false;
    }

    const save = { email, password, instagram, avatar: user.profile_pic_url, instagramId: user.id };

    if (localStorage.getItem('ref')) {
      save.ref = localStorage.getItem('ref');
    }

    const res = await api.post("/user/register", save);

    if (res.data.error) {
      enqueueSnackbar(res.data.error, {
        variant: 'error',
      });
      return false;
    }

    if (res.status === 200 && res.data) {
      setLoginData(res.data.user);
      setAuthToken(res.data.token);
    }
  }

  if (authToken) {
    return <Redirect to={referer} />;
  }

  return (
    <Grid container alignItems="center" justify="center">
      <Grid item xs={12} lg={4}>
        <Paper className={classes.paper}>
          <Container component="main" maxWidth="xs">
            <div className={classes.paper}>
              <Avatar className={classes.avatar}>
                <AccountCircleIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                {t('Signup.createAccountTitle')}
              </Typography>
              <form className={classes.form} noValidate>
                <TextField
                  error={!!instagramError}
                  helperText={instagramError}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="instagram"
                  label={t('Signup.instagramInput')}
                  name="instagram"
                  autoFocus
                  value={instagram}
                  onChange={e => {
                    setInstagramError("");
                    let value = e.target.value.replace(/[^A-Za-z0-9_.]/g, '').toLowerCase();
                    if (value) {
                      value = '@' + value;
                    }
                    setInstagram(value);
                  }}
                />
                <TextField
                  error={!!emailError}
                  helperText={emailError}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label={t('Signup.emailInput')}
                  name="email"
                  value={email}
                  onChange={e => {
                    setEmailError("");
                    setEmail(e.target.value);
                  }}
                />
                <TextField
                  error={!!passwordError}
                  helperText={passwordError}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label={t('Signup.passwordInput')}
                  type="password"
                  id="password"
                  value={password}
                  onChange={e => {
                    setPasswordError("");
                    setPassword(e.target.value);
                  }}
                />
                <TextField
                  error={!!confirmPasswordError}
                  helperText={confirmPasswordError}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="confirm-password"
                  label={t('Signup.passwordConfirmInput')}
                  type="password"
                  id="confirm-password"
                  value={confirmPassword}
                  onChange={e => {
                    setConfirmPasswordError("");
                    setConfirmPassword(e.target.value);
                  }}
                />
                <FormControlLabel
                  control={<Checkbox checked={agree} color="default" />}
                  label={<span className={classes.labelCheckbox}>{t('Signup.termsAgree')} <Link href="#" onClick={openTermsModal}>{t('Signup.termsAgreeLink')}</Link></span>}
                  onChange={e => {
                    setAgree(!agree);
                  }}
                />
                <Button
                  type="submit"
                  fullWidth
                  disableElevation
                  size="large"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={onSignup}
                >
                  {t('Signup.createAccountButton')}
                </Button>
                <Grid container justify="flex-end">
                  <Grid item>
                    <Link href="/login" variant="body2">
                      {t('Signup.alreadyHaveAccount')}
                    </Link>
                  </Grid>
                </Grid>
              </form>
            </div>
          </Container>
        </Paper>
      </Grid>
      <Terms
        onClose={e => setOpenTerms(false)}
        onClick={e => setAgree(true)}
        open={openTerms}
      />
    </Grid>
  );
}

export default Signup;