import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import useMediaQuery from '@material-ui/core/useMediaQuery'

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

function RenameAccount(props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [ instagram, setInstagram ] = useState("");
  const [ instagramError, setInstagramError ] = useState("");

  const onClose = (e) => {
    setInstagram("");
    setInstagramError("");
    props.onClose();
  }

  const onClick = (e) => {
    e.preventDefault();

    if (!instagram) {
      setInstagramError(t('Default.requiredField'));
      return false;
    }

    props.onSave(instagram);
    onClose();
  };

  return (
    <Dialog fullScreen={fullScreen} open={props.open} onClose={onClose}>
      <DialogTitle id="form-dialog-title">{t('Modal.RenameAccount.title')}</DialogTitle>
      <form onSubmit={e => onClick(e)} noValidate>
        <DialogContent>
          <DialogContentText>
            {t('Modal.RenameAccount.text')}
          </DialogContentText>
          <TextField
            error={!!instagramError}
            helperText={instagramError}
            variant="outlined"
            margin="normal"
            autoFocus
            required
            fullWidth
            id="instagram"
            label={t('Modal.RenameAccount.instagramInput')}
            name="instagram"
            value={instagram}
            onChange={e => {
              setInstagramError("");
              let value = e.target.value.replace(/[^A-Za-z0-9_.]/g, '').toLowerCase();
              if (value) {
                value = '@' + value;
              }
              setInstagram(value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="inherit">
            {t('Modal.RenameAccount.noButton')}
          </Button>
          <Button type="submit" onClick={onClick} color="inherit">
            {t('Modal.RenameAccount.yesButton')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}


export default RenameAccount;
