import React from 'react';
import { useTranslation } from 'react-i18next';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { useAuth } from 'context/auth';
import { addProtocol } from 'utils';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    boxShadow: 'none',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  btn: {
    fontSize: 14,
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 11,
    },
    textAlign: 'center',
  },
}));

function AppBarUser(props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { authToken, loginData } = useAuth();
  return (
    <div>
      <AppBar className={classes.appBar} color="inherit" position="fixed">
        <Toolbar>
          <List className={classes.root}>
            <ListItem target="_blank" style={{ color: 'rgba(0, 0, 0, 0.87)' }} component="a" href={`https://instagram.com/${props.user.instagramUsername}`}>
              <ListItemAvatar>
                <Avatar
                  alt={'@' + props.user.instagramUsername}
                  src={props.user.avatar}
                />
              </ListItemAvatar>
              <Hidden xsDown>
                <Typography variant="h6" component="h1">@{props.user.instagramUsername}</Typography>
              </Hidden>
              <Hidden smUp>
                <Typography variant="body1" component="h1">@{props.user.instagramUsername}</Typography>
              </Hidden>
            </ListItem>
          </List>
          <div>
            <Hidden xsDown>
              {authToken && loginData && loginData.accounts.find(acc => acc.instagramUsername === props.user.instagramUsername) ? (
                <Button href="/app" variant="outlined" disableElevation style={{ marginRight: 20 }}>{t('AppBarUser.myAccount')}</Button>
              ) : null}
            </Hidden>
            <Button className={classes.btn} target="_blank" href={addProtocol(props.user.buttonLink) || '#'} variant="outlined" disableElevation>{props.user.buttonText || ''}</Button>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default AppBarUser;
