import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import { validateEmail } from 'utils';

import useMediaQuery from '@material-ui/core/useMediaQuery'

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

function ForgotPassword(props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [ email, setEmail ] = useState("");
  const [ emailError, setEmailError ] = useState("");

  const onClick = (e) => {
    e.preventDefault();
    if (!email) {
      setEmailError(t('Default.requiredField'));
      return false;
    }

    if (!validateEmail(email)) {
      setEmailError(t('Default.invalidEmail'));
      return false;
    }

    props.onSave(email);
    onClose();
  };

  const onClose = () => {
    setEmail("");
    setEmailError("");
    props.onClose();
  };

  return (
    <Dialog fullScreen={fullScreen} open={props.open} onClose={onClose}>
      <DialogTitle id="form-dialog-title">{t('Modal.ForgotPassword.title')}</DialogTitle>
      <form onSubmit={e => onClick(e)} noValidate>
        <DialogContent>
          <DialogContentText>
            {t('Modal.ForgotPassword.text')}
          </DialogContentText>
          <TextField
            error={!!emailError}
            helperText={emailError}
            variant="outlined"
            margin="normal"
            autoFocus
            required
            fullWidth
            id="email"
            label={t('Modal.ForgotPassword.emailInput')}
            name="email"
            value={email}
            onChange={e => {
              setEmailError("");
              setEmail(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="inherit">
            {t('Modal.ForgotPassword.noButton')}
          </Button>
          <Button type="submit" onClick={onClick} color="inherit">
            {t('Modal.ForgotPassword.yesButton')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default ForgotPassword;
