import React, { useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

import AddIcon from '@material-ui/icons/Add';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

import { useApp } from 'context/app';
import { useAuth } from 'context/auth';
import { api } from 'services/api';

import AddAccount from 'pages/Modal/AddAccount';
import ChangeEmail from 'pages/Modal/ChangeEmail';
import ChangePassword from 'pages/Modal/ChangePassword';
import RemoveAccount from 'pages/Modal/RemoveAccount';
import RenameAccount from 'pages/Modal/RenameAccount';
import RemoveUser from 'pages/Modal/RemoveUser';

const useStyles = makeStyles(theme => ({
  inline: {
    display: 'inline',
  },
  card: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  addAccountIcon: {
    color: theme.palette.primary.light,
    backgroundColor: theme.palette.primary.main,
    cursor: 'pointer',
  },
  letterAccountIcon: {
    color: theme.palette.primary.light,
    backgroundColor: theme.palette.primary.main
  },
}));

function Settings() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useApp();
  const { setAuthToken, setLoginData, loginData } = useAuth();
  const [ openChangeEmail, setOpenChangeEmail ] = useState(false);
  const [ openChangePassword, setOpenChangePassword ] = useState(false);
  const [ openRemoveUser, setOpenRemoveUser ] = useState(false);
  const [ openRemoveAccount, setOpenRemoveAccount ] = useState(false);
  const [ openAddAccount, setOpenAddAccount ] = useState(false);
  const [ openRenameAccount, setOpenRenameAccount ] = useState(false);
  const [ selectedAccount, setSelectedAccount ] = useState({});
  
  const classes = useStyles();

  const saveEmailChange = async (newEmail) => {
    const result = await api.put(`/user/${loginData._id}`, {
      email: newEmail,
    });
    
    if (result.data.error) {
      enqueueSnackbar(result.data.error, {
        variant: 'error',
      });
      setOpenChangeEmail(false);
      return false;
    }
    setOpenChangeEmail(false);
    enqueueSnackbar(t('Settings.emailChangedDescription'), {
      variant: 'info',
    });
    setAuthToken();
    setLoginData();
  };

  const savePasswordChange = async (newPassword) => {
    const result = await api.put(`/user/${loginData._id}`, {
      password: newPassword,
    });
    
    if (result.data.error) {
      enqueueSnackbar(result.data.error, {
        variant: 'error',
      });
      setOpenChangePassword(false);
      return false;
    }
    enqueueSnackbar(t('Settings.passwordChanged'), {
      variant: 'success',
    });
    setOpenChangePassword(false);
  };

  const saveRemoveUser = async () => {
    const result = await api.delete(`/user/${loginData._id}`);

    if (result.data.error) {
      enqueueSnackbar(result.data.error, {
        variant: 'error',
      });
      setOpenRemoveUser(false);
      return false;
    }
    setOpenRemoveUser(false);
    enqueueSnackbar(t('Settings.userRemoved'), {
      variant: 'info',
    });
    setAuthToken();
    setLoginData();
  };

  const saveRemoveAccount = async () => {
    const result = await api.delete(`/account/${selectedAccount._id}`);

    if (result.data.error) {
      enqueueSnackbar(result.data.error, {
        variant: 'error',
      });
      setOpenRemoveAccount(false);
      return false;
    }
    const cp = loginData;
    for (let index in cp.accounts) {
      if (cp.accounts[index].instagramId === selectedAccount.instagramId) {
        cp.accounts.splice(index, 1);
      }
    }
    enqueueSnackbar(t('Settings.accountRemoved', { value: selectedAccount.instagramUsername }), {
      variant: 'info',
    });
    setLoginData(Object.assign({}, cp));
    setOpenRemoveAccount(false);
  };

  const saveRenameAccount = async (newName) => {
    const instagramData = await api.get(`/user/instagram/${newName.replace('@', '')}`);
    const user = _.get(instagramData, 'data.graphql.user');

    if (!user || !user.id) {
      enqueueSnackbar(t('Signup.instagramNotFound'), {
        variant: 'error',
      });
      return false;
    }

    if (user.id !== selectedAccount.instagramId) {
      enqueueSnackbar(t('Signup.instagramNotEqual'), {
        variant: 'error',
      });
      return false;
    }

    const result = await api.put(`/account/${selectedAccount._id}`, {
      instagramUsername: newName.replace('@', ''),
      avatar: user.profile_pic_url,
      instagramId: user.id,
    });

    if (result.data.error) {
      enqueueSnackbar(result.data.error, {
        variant: 'error',
      });
      setOpenRenameAccount(false);
      return false;
    }

    const cp = loginData;
    const index = cp.accounts.findIndex(l => l.instagramId === result.data.instagramId);
    cp.accounts[index] = result.data;
    setLoginData(Object.assign({}, cp));
    enqueueSnackbar(t('Settings.accountRenamed'), {
      variant: 'success',
    });
    setOpenRenameAccount(false);
  };

  const selectAccount = async (accountId) => {
    await api.put(`/account/${accountId}`, {
      selected: true
    });
    const cp = loginData;
    cp.accounts.forEach(l => {
      if (l._id === accountId) {
        l.selected = true;
      } else {
        l.selected = false;
      }
    });
    return setLoginData(Object.assign({}, cp));
  };

  const saveAddAccount = async (newAccount) => {
    newAccount = newAccount.replace('@', '');

    const instagramData = await api.get(`/user/instagram/${newAccount}`);
    const user = _.get(instagramData, 'data.graphql.user');

    if (!user || !user.id) {
      enqueueSnackbar(t('Signup.instagramNotFound'), {
        variant: 'error',
      });
      return false;
    }

    if (user.is_private) {
      enqueueSnackbar(t('Signup.instagramPrivate'), {
        variant: 'error',
      });
      return false;
    }

    const result = await api.post(`/account`, {
      instagramUsername: newAccount,
      avatar: user.profile_pic_url,
      instagramId: user.id,
    });

    if (result.data.error) {
      enqueueSnackbar(result.data.error, {
        variant: 'error',
      });
      setOpenAddAccount(false);
      return false;
    }
    
    const cp = loginData;
    cp.accounts.push(result.data);
    setLoginData(Object.assign({}, cp));
    setOpenAddAccount(false);
    selectAccount(result.data._id);
  };

  return (
    <React.Fragment>
      <Grid container justify="center">
        <Grid item xs={12} lg={6} style={{ marginBottom: 20 }}>
          <Container component="main">
            <Card className={classes.card}>
              <CardHeader title={t('Settings.yourAccount')} subheader={t('Settings.yourAccount2')} />
              <CardContent>
                <List>
                  <ListItem alignItems="flex-start" style={{ paddingLeft: 0 }}>
                    <ListItemAvatar>
                      <Avatar
                        className={classes.letterAccountIcon}>
                      {loginData.email[0].toUpperCase()}</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={loginData.email}
                      secondary={
                        <Typography variant="body2">
                          {t('Settings.createdAt', { value: moment(loginData.createdAt) })}
                        </Typography>
                      } />
                  </ListItem>
                </List>
              </CardContent>
              <CardActions>
                <Button color="inherit" onClick={e => setOpenChangeEmail(true)}>{t('Settings.changeEmail')}</Button>
                <Button color="inherit" onClick={e => setOpenChangePassword(true)}>{t('Settings.changePassword')}</Button>
                <Button color="inherit" onClick={e => setOpenRemoveUser(true)}>{t('Settings.removeAccount')}</Button>
              </CardActions>
            </Card>
          </Container>
        </Grid>
        <Grid item xs={12} lg={6} style={{ marginBottom: 20 }}>
          <Container component="main">
            <Card className={classes.card}>
              <CardHeader title={t('Settings.yourInstagramAccounts')} subheader={t('Settings.yourInstagramAccounts2')} />
              <CardContent>
                <List>
                  {loginData.accounts.sort((u1, u2) => u2.selected - u1.selected).map((login, i) => 
                    (
                      <React.Fragment key={i}>
                        <ListItem alignItems="flex-start" style={{ paddingLeft: 0 }}>
                          <ListItemAvatar>
                            <Avatar alt={login.instagramUsername} src={login.avatar} />
                          </ListItemAvatar>
                          <ListItemText
                            primary={'@' + login.instagramUsername}
                            secondary={
                              <React.Fragment>
                                {login.selected ? (
                                  <Typography component="span" style={{ color: '#333'}} variant="body2">
                                    {t('Settings.accountInUse')}
                                  </Typography>
                                ) : (
                                  <React.Fragment>
                                    <Link color="inherit" onClick={() => {
                                      selectAccount(login._id);
                                    }} href="#">
                                      {t('Settings.selectInstagramAccount')}
                                    </Link>
                                  </React.Fragment>
                                )}
                                {' — '}
                                <Link color="inherit" onClick={() => {
                                  setSelectedAccount(login);
                                  setOpenRenameAccount(true);
                                }} href="#">
                                  {t('Settings.renameInstagramAccount')}
                                </Link>
                                {!login.selected ? (
                                  <React.Fragment>
                                    {' — '}
                                    <Link color="inherit" onClick={() => {
                                      setOpenRemoveAccount(true);
                                      setSelectedAccount(login);
                                    }} href="#">
                                      {t('Settings.removeInstagramAccount')}
                                    </Link>
                                  </React.Fragment>
                                ) : null}
                              </React.Fragment>
                            }
                          />
                        </ListItem>
                        <Divider variant="inset" component="li" />
                      </React.Fragment>
                    )
                  )}
                  <ListItem alignItems="flex-start" style={{ paddingLeft: 0 }}>
                    <ListItemAvatar>
                      <Avatar
                        alt="Adicionar outra conta"
                        className={classes.addAccountIcon}
                        onClick={() => setOpenAddAccount(true)}
                        >
                        <AddIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={t('Settings.newAccount')}
                      secondary={
                        <React.Fragment>
                          <Link color="inherit" onClick={() => setOpenAddAccount(true)} href="#">
                            {t('Settings.addNewAccount')}
                          </Link>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                </List>
              </CardContent>
            </Card>
          </Container>
        </Grid>
      </Grid>
      <ChangeEmail
        onClose={e => setOpenChangeEmail(false)}
        onSave={newEmail => saveEmailChange(newEmail)}
        open={openChangeEmail}
      />
      <ChangePassword
        onClose={e => setOpenChangePassword(false)}
        onSave={newPassword => savePasswordChange(newPassword)}
        open={openChangePassword}
      />
      <RemoveUser
        onClose={e => setOpenRemoveUser(false)}
        onSave={() => saveRemoveUser()}
        open={openRemoveUser}
      />
      <RemoveAccount
        onClose={e => setOpenRemoveAccount(false)}
        onSave={() => saveRemoveAccount()}
        open={openRemoveAccount}
        data-instagram={selectedAccount}
      />
      <AddAccount
        onClose={e => setOpenAddAccount(false)}
        onSave={(account) => saveAddAccount(account)}
        open={openAddAccount}
      />
      <RenameAccount
        onClose={e => setOpenRenameAccount(false)}
        onSave={(newName) => saveRenameAccount(newName)}
        open={openRenameAccount}
        data-instagram={selectedAccount}
      />
    </React.Fragment>
  );
}

export default Settings;
