import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from 'App';
import * as serviceWorker from 'serviceWorker';

import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import blue from '@material-ui/core/colors/blue';
import { enUS, ptBR, esES } from '@material-ui/core/locale';

import 'typeface-roboto';
import 'typeface-karla';
import 'index.css';
import 'i18n';

const primaryMain = blue[500];
const primaryLight = blue[50];
const primaryDark = blue[800];
const secondaryMain = blue[50];
const secondaryLight = blue[900];
const secondaryDark = blue[800];

const overridings = {
  name: 'linkna.bio Black & White Theme',
  palette: {
    primary: {
      main: primaryMain,
      light: primaryLight,
      dark: primaryDark,
      contrastText: "#FFF"
    },
    secondary: {
      main: secondaryMain,
      light: secondaryLight,
      dark: secondaryDark,
      contrastText: "#333"
    }
  }
};

const getUserLocale = () => {
  const lng = (navigator.language || navigator.userLanguage).substr(0, 2);
  switch (lng) {
    case 'en':
      return enUS;
    case 'es':
      return esES;
    case 'pt':
      return ptBR;
    default:
      return enUS;
  }
};

const theme = createMuiTheme(overridings, getUserLocale());

ReactDOM.render(
  <Router>
    <ThemeProvider theme = { theme }>
      <SnackbarProvider maxSnack={3} anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}>
        <App />
      </SnackbarProvider>
    </ThemeProvider>
  </Router>, 
  document.getElementById('root') 
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
