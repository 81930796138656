export const validateEmail = (email) => {
  // eslint-disable-next-line
  var re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return re.test(String(email).toLowerCase());
};

export const validateUrl = (url) => {
  // eslint-disable-next-line
  var re = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm;
  return re.test(String(url));
};

export const addProtocol = (url) => {
  if (!url) {
    return null;
  }
  if(!/^https?:\/\//i.test(url)){
    return `//${url}`;
  }
  return url;
}