import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  headerButtons: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, onClick, t } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      <React.Fragment>
        <div className={classes.headerButtons}>
          <Button onClick={onClose} color="inherit">
            {t('Modal.Terms.noButton')}
          </Button>
          <Button onClick={onClick} color="inherit">
            {t('Modal.Terms.yesButton')}
          </Button>
        </div>
      </React.Fragment>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function Terms(props) {
  const { t } = useTranslation();

  const onClick = () => {
    onClose();
    props.onClick();
  };

  const onClose = () => {
    props.onClose();
  };

  return (
    <div>
    <Dialog
      fullScreen
      open={props.open}
      onClose={onClose}
    >
      <DialogTitle t={t} id="alert-dialog-title" onClose={onClose} onClick={onClick}>
        {t('Modal.Terms.title')}
      </DialogTitle>
      <form noValidate>
        <DialogContent>
          <div style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={{__html: t('Modal.Terms.text')}} />
          <div style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={{__html: t('Modal.Terms.text')}} />
          <div style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={{__html: t('Modal.Terms.text')}} />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="inherit">
            {t('Modal.Terms.noButton')}
          </Button>
          <Button onClick={onClick} color="inherit">
            {t('Modal.Terms.yesButton')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
    </div>
  );
};

export default Terms;
