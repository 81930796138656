/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import clsx from 'clsx';
import { api } from 'services/api';
import { useAuth } from 'context/auth';
import { useApp } from 'context/app';

import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import Fab from '@material-ui/core/Fab';
import Hidden from '@material-ui/core/Hidden';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import CloseIcon from '@material-ui/icons/Close';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MenuIcon from '@material-ui/icons/Menu';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import { validateUrl } from 'utils';

import ColorPicker from 'components/ColorPicker';

const drawerWidth = '100%';

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(17),
    [theme.breakpoints.up('lg')]: {
      width: theme.spacing(50),
    },
  },
  rightBar: {
    paddingTop: theme.spacing(10),
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(3),
    zIndex: 1203,
  },
  closeIcon: {
    position: "fixed",
    top: theme.spacing(2),
    right: theme.spacing(3),
  },
}));

const StyledSwitch = withStyles({
  root: {
    left: '10px',
    top: '-7px',
  },
})(Switch);

function AppBarRight() {

  const { loginData, setLoginData } = useAuth();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useApp();
  const classes = useStyles();

  const [ openDrawer, setOpenDrawer ] = useState(false);
  const [ expanded, setExpanded ] = useState(false);
  const [ selectedUser, setSelectedUser ] = useState({});
  const [ buttonTextError, setButtonTextError ] = useState("");
  const [ buttonLinkError, setButtonLinkError ] = useState("");

  const linkRef = useRef(null);
  const btnRef = useRef(null);

  const siteUrl = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_SITE_URL_PROD : process.env.REACT_APP_SITE_URL;

  useEffect(() => {
    const selected = loginData.accounts.find(l => l.selected) || _.get(loginData, 'accounts[0]');
    if (selected) {
      setSelectedUser(selected);
    }
  }, [loginData]);

  useEffect(() => {
    if (selectedUser._id) {
      updateLoginData(selectedUser, false);
    }
  }, [
    selectedUser.backgroundColor,
    selectedUser.backgroundEndColor,
    selectedUser.menuColor,
    selectedUser.menuTextColor,
    selectedUser.outlinedMenu,
    selectedUser.gradient
  ]);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const onChangeMenuColor = (color) => {
    setSelectedUser({
      ...selectedUser,
      menuColor: color,
    });
  };

  const onChangeOutlinedMenu = (e) => {
    setSelectedUser({
      ...selectedUser,
      outlinedMenu: e.target.checked,
    });
  };
  
  const onChangeBackgroundColor = (color) => {
    setSelectedUser({
      ...selectedUser,
      backgroundColor: color,
    });
  };

  const onChangeBackgroundEndColor = (color) => {
    setSelectedUser({
      ...selectedUser,
      backgroundEndColor: color,
    });
  };

  const onChangeGradient = (e) => {
    setSelectedUser({
      ...selectedUser,
      gradient: e.target.checked,
    });
  };

  const copyLink = (e) => {
    if (linkRef) {
      linkRef.current.select();
      linkRef.current.setSelectionRange(0, 99999);
      btnRef.current.focus();
      document.execCommand('copy');
      enqueueSnackbar(t('AppBarRight.linkCopied'), {
        variant: 'info',
      });
    }
  };

  const saveCustomization = async (e) => {
    e.preventDefault();
    let error = false;

    if (!selectedUser.buttonText) {
      setButtonTextError(t('Default.requiredField'));
      error = true;
    }

    if (selectedUser.buttonText.length > 20) {
      setButtonTextError(t('Default.maxChars', { value: 20 }));
      error = true;
    }

    if (!selectedUser.buttonLink) {
      setButtonLinkError(t('Default.requiredField'));
      error = true;
    }

    if (selectedUser.buttonLink && !validateUrl(selectedUser.buttonLink)) {
      setButtonLinkError(t('Default.invalidLink'));
      error = true;
    }

    if (error) {
      return false;
    }

    const result = await api.put(`/account/${selectedUser._id}`, {
      buttonText: selectedUser.buttonText,
      buttonLink: selectedUser.buttonLink,
      menuColor: selectedUser.menuColor,
      menuTextColor: selectedUser.menuTextColor,
      outlinedMenu: selectedUser.outlinedMenu,
      backgroundColor: selectedUser.backgroundColor,
      backgroundEndColor: selectedUser.backgroundEndColor,
      gradient: selectedUser.gradient,
      accountType: selectedUser.accountType,
    });

    if (result.data.error) {
      enqueueSnackbar(result.data.error, {
        variant: 'error',
      });
      return false;
    }

    if (result.status === 200 && result.data) {
      updateLoginData(result.data, true);
    }
  };

  const updateLoginData = (data, notify) => {
    const cp = loginData;
    const index = cp.accounts.findIndex(l => l.instagramId === data.instagramId);
    cp.accounts[index] = data;
    setLoginData(Object.assign({}, cp));
    if (notify) {
      enqueueSnackbar(t('AppBarRight.dataUpdated'), {
        variant: 'success',
      });
    }
  };

  const drawer = (
    <div className={classes.rightBar}>
      <Hidden lgUp implementation="js">
        <CloseIcon className={classes.closeIcon} onClick={handleDrawerClose} />
      </Hidden>
      <Grid container>
        <Grid item xs={12}>
          <div style={{ margin: '20px' }}>
            <Button
              variant="outlined"
              color="primary"
              target="_blank"
              fullWidth
              className={classes.button}
              href={`${siteUrl}/${selectedUser.instagramUsername}`}
              endIcon={<ExitToAppIcon />}
            >
              {t('AppBarRight.viewPage')}
            </Button>
          </div>
        </Grid>
        <Grid item xs={12}>
          <ExpansionPanel square expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header">
              <Typography className={classes.heading}>{t('AppBarRight.yourPage')}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid container>
                <Grid item xs={12}>
                  <Typography style={{ fontSize: 16 }} variant="subtitle2">
                    {t('AppBarRight.yourLink')}
                  </Typography>
                  <Typography style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                    {t('AppBarRight.copyPasteMessage')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin={'normal'}
                    label="Link"
                    variant="outlined"
                    fullWidth
                    readOnly
                    value={`${siteUrl}/${selectedUser.instagramUsername}`}
                    inputRef={linkRef}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    color="primary"
                    size="large"
                    variant="contained"
                    fullWidth
                    disableElevation
                    onClick={(e) => copyLink(e)}
                    ref={btnRef}>
                    {t('AppBarRight.copyLink')}
                  </Button>
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel square expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header">
              <Typography className={classes.heading}>{t('AppBarRight.customization')}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails style={{ overflowY: 'auto', maxHeight: 250 }}>
              <Grid container >
                {selectedUser.accountType === 'menu' ? (
                  <React.Fragment>
                    <Grid item xs={12}>
                      <Typography style={{ fontSize: 16 }} variant="subtitle2">
                        {t('AppBarRight.colors')}
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography variant="body2" gutterBottom style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                        {t('AppBarRight.menuColor')}
                      </Typography>
                    </Grid>
                    <Grid item xs={3} align="right">
                      <ColorPicker color={selectedUser.menuColor || { r: 51, g: 51, b: 51, a: 1 }} onChangeComplete={onChangeMenuColor} />
                    </Grid>
                    <Grid item xs={9}>
                      <Typography variant="body2" gutterBottom style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                        {t('AppBarRight.outlinedMenu')}
                      </Typography>
                    </Grid>
                    <Grid item xs={3} align="right">
                      <StyledSwitch color="default" checked={selectedUser.outlinedMenu} onChange={onChangeOutlinedMenu} />
                    </Grid>
                    <Grid item xs={9}>
                      <Typography variant="body2" gutterBottom style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                        {t('AppBarRight.initialBackGroundColor', { value: selectedUser.gradient ? t('AppBarRight.initial') : '' })}
                      </Typography>
                    </Grid>
                    <Grid item xs={3} align="right">
                      <ColorPicker color={selectedUser.backgroundColor || { r: 255, g: 255, b: 255, a: 1 }} onChangeComplete={onChangeBackgroundColor} />
                    </Grid>
                    <Grid item xs={9}>
                      <Typography variant="body2" gutterBottom style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                        {t('AppBarRight.gradientInBackground')}
                      </Typography>
                    </Grid>
                    <Grid item xs={3} align="right">
                      <StyledSwitch color="default" checked={selectedUser.gradient} onChange={onChangeGradient} />
                    </Grid>
                    {selectedUser.gradient ? (
                      <React.Fragment>
                        <Grid item xs={9} style={{ marginTop: '-8px'}}>
                          <Typography variant="body2" gutterBottom style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                            {t('AppBarRight.endBackgroundColor')}
                          </Typography>
                        </Grid>
                        <Grid item xs={3} align="right" style={{ marginTop: '-8px'}}>
                          <ColorPicker color={selectedUser.backgroundEndColor || { r: 255, g: 255, b: 255, a: 1 }} onChangeComplete={onChangeBackgroundEndColor} />
                        </Grid>
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>
                ) : null}
                <Grid item xs={12}>
                  <Typography style={{ fontSize: 16 }} variant="subtitle2">
                    {t('AppBarRight.mainButton')}
                  </Typography>
                  <Typography gutterBottom style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                    {t('AppBarRight.mainButtonDescription')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={!!buttonTextError}
                    helperText={buttonTextError}
                    margin={'normal'}
                    id="buttonText"
                    label={t('AppBarRight.buttonText')}
                    variant="outlined"
                    fullWidth
                    value={selectedUser.buttonText || ''}
                    onChange={e => {
                      setButtonTextError("");
                      setSelectedUser({
                       ...selectedUser,
                       buttonText: e.target.value,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={!!buttonLinkError}
                    helperText={buttonLinkError}
                    margin={'normal'}
                    id="buttonLink"
                    label={t('AppBarRight.buttonText')}
                    variant="outlined"
                    fullWidth
                    value={selectedUser.buttonLink || ''}
                    onChange={e => {
                      setButtonLinkError("");
                      setSelectedUser({
                       ...selectedUser,
                       buttonLink: e.target.value,
                      });
                    }}
                  />
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
            <ExpansionPanelActions>
              <Button
                color="primary"
                size="large"
                variant="contained"
                fullWidth
                disableElevation
                style={{ marginBottom: 10, marginTop: 10 }}
                onClick={saveCustomization}>
                  {t('AppBarRight.saveCustomizationButton')}
              </Button>
            </ExpansionPanelActions>
          </ExpansionPanel>
        </Grid>
      </Grid>
    </div>
  );

  return (
    <React.Fragment>
      <Hidden lgUp implementation="js">
        <Fab color="primary" aria-label="add" className={classes.fab} onClick={handleDrawerOpen}>
          <MenuIcon />
        </Fab>
        <Drawer
          variant="temporary"
          anchor={'right'}
          onClose={handleDrawerClose}
          classes={{
            paper: clsx(classes.drawerPaper, !openDrawer && classes.drawerPaperClose),
          }}
          open={openDrawer}
          ModalProps={{
            keepMounted: true,
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden mdDown implementation="js">
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !openDrawer && classes.drawerPaperClose),
          }}
          open={openDrawer}
        >
          {drawer}
        </Drawer>
      </Hidden>
    </React.Fragment>
  );
}

export default AppBarRight;