import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import useMediaQuery from '@material-ui/core/useMediaQuery'

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

function RemoveLink(props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const onClick = (e) => {
    e.preventDefault();
    props.onSave();
  };

  const onClose = () => {
    props.onClose();
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={props.open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{t('Modal.RemoveLink.title')}</DialogTitle>
      <form onSubmit={e => onClick(e)} noValidate>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('Modal.RemoveLink.text')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="inherit">
            {t('Modal.RemoveLink.noButton')}
          </Button>
          <Button onClick={onClick} color="inherit">
            {t('Modal.RemoveLink.yesButton')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default RemoveLink;
