import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import 'moment/locale/pt';
import 'moment/locale/es';

const getUserLocale = () => {
  const lng = (navigator.language || navigator.userLanguage).substr(0, 2);
  switch (lng) {
    case 'en':
      moment.locale('en');
      return 'en';
    case 'es':
      moment.locale('es');
      return 'es';
    case 'pt':
      moment.locale('pt');
      return 'pt';
    default:
      moment.locale('en');
      return 'en';
  }
};

function SelectRange(props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [ initialDate, setInitialDate ] = useState(new Date());
  const [ finalDate, setFinalDate ] = useState(new Date());
  const [ lng, setLng ] = useState("en");

  const [ initialDateError, setInitialDateError ] = useState("");
  const [ finalDateError, setFinalDateError ] = useState("");

  useEffect(() => {
    setLng(getUserLocale());
  }, []);

  const handleInitialDateChange = date => {
    setInitialDate(date);
  };

  const handleFinalDateChange = date => {
    setFinalDate(date);
  };

  const onClick = (e) => {
    e.preventDefault();

    if (!initialDate) {
      setInitialDateError(t('Default.requiredField'));
      return false;
    }

    if (!moment(initialDate).isValid()) {
      setInitialDateError(t('Default.invalidDate'));
      return false;
    }

    if (!finalDate) {
      setFinalDateError(t('Default.requiredField'));
      return false;
    }

    if (!moment(finalDate).isValid()) {
      setFinalDateError(t('Default.invalidDate'));
      return false;
    }

    if (moment(initialDate).startOf('day') > moment(finalDate).startOf('day')) {
      setInitialDateError(t('Modal.SelectRange.dateError'));
      return false;
    }

    props.onSave(initialDate, finalDate);
    onClose();
  };

  const onClose = () => {
    setInitialDate(new Date());
    setFinalDate(new Date());
    setInitialDateError("");
    setFinalDateError("");
    props.onClose();
  };

  return (
    <Dialog fullScreen={fullScreen} open={props.open} onClose={onClose}>
      <DialogTitle id="form-dialog-title">{t('Modal.SelectRange.title')}</DialogTitle>
      <form onSubmit={e => onClick(e)} noValidate>
        <DialogContent>
          <MuiPickersUtilsProvider utils={MomentUtils} locale={lng}>
              <Box>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format={t('Default.dateFormat')}
                  margin="normal"
                  error={!!initialDateError}
                  helperText={initialDateError}
                  maxDate={new Date()}
                  label={t('Modal.SelectRange.initialDate')}
                  value={initialDate}
                  onChange={handleInitialDateChange}
                />
              </Box>
              <Box>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format={t('Default.dateFormat')}
                  margin="normal"
                  error={!!finalDateError}
                  helperText={finalDateError}
                  maxDate={new Date()}
                  label={t('Modal.SelectRange.finalDate')}
                  value={finalDate}
                  onChange={handleFinalDateChange}
                />
              </Box>
          </MuiPickersUtilsProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="inherit">
            {t('Modal.SelectRange.noButton')}
          </Button>
          <Button type="submit" onClick={onClick} color="inherit">
            {t('Modal.SelectRange.yesButton')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}


export default SelectRange;
