import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import _ from 'lodash';

import { validateEmail } from 'utils';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useAuth } from 'context/auth';
import { useApp } from 'context/app';
import { api } from 'services/api';
import ForgotPassword from 'pages/Modal/ForgotPassword';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

const useStyles = makeStyles(theme => ({
  paper: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  }, 
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function Login(props) {
  const { t } = useTranslation();
  const [ remember, setRemember ] = useState(false);
  const [ email, setEmail ] = useState("");
  const [ password, setPassword ] = useState("");
  const { authToken, setAuthToken, setLoginData } = useAuth();
  const { enqueueSnackbar } = useApp();
  const [ emailError, setEmailError ] = useState("");
  const [ passwordError, setPasswordError ] = useState("");
  const [ openForgotPassword, setOpenForgotPassword ] = useState(false);

  const referer = _.get(props, 'location.state.referer', '/app');
  const classes = useStyles();

  useEffect(() => {
    if (localStorage.getItem('remember')) {
      setRemember(true);
      setEmail(localStorage.getItem('email'));
      setPassword(localStorage.getItem('password'));
    }
  }, []);

  const saveForgotPassword = async (email) => {
    const result = await api.post('/user/forgot-password', { email });

    if (result.data.error) {
      enqueueSnackbar(result.data.error, {
        variant: 'error',
      });
      setOpenForgotPassword(false);
      return false;
    }

    enqueueSnackbar(t('Login.changePasswordInstructions'), {
      variant: 'success',
    });
  };

  const onLogin = async (e) => {
    e.preventDefault();
    let error = false;
    if (!email) {
      setEmailError(t('Default.requiredField'));
      error = true;
    }

    if (!validateEmail(email)) {
      setEmailError(t('Default.invalidEmail'));
      error = true;
    }

    if (!password) {
      setPasswordError(t('Default.requiredField'));
      error = true;
    }

    if (error) {
      return false;
    }

    if (remember) {
      localStorage.setItem('remember', true);
      localStorage.setItem('email', email);
      localStorage.setItem('password', password);
    } else {
      localStorage.removeItem('remember');
      localStorage.removeItem('email');
      localStorage.removeItem('password');
    }

    const result = await api.post('/user/login', { email, password });

    if (result.data.error) {
      enqueueSnackbar(result.data.error, {
        variant: 'error',
      });
      return false;
    }

    if (result.status === 200 && result.data) {
      setLoginData(result.data.user);
      setAuthToken(result.data.token);
    }
  }

  if (authToken) {
    return <Redirect to={referer} />;
  }

  return (
    <React.Fragment>
      <Grid container alignItems="center" justify="center">
        <Grid item xs={12} lg={4}>
          <Paper className={classes.paper}>
            <Container component="main" maxWidth="xs">
              <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                  {t('Login.loginTitle')}
                </Typography>
                <form className={classes.form} noValidate>
                  <TextField
                    error={!!emailError}
                    helperText={emailError}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label={t('Login.emailInput')}
                    name="email"
                    type="email"
                    autoFocus
                    value={email}
                    onChange={e => {
                      setEmailError("");
                      setEmail(e.target.value);
                    }}
                  />
                  <TextField
                    error={!!passwordError}
                    helperText={passwordError}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label={t('Login.passwordInput')}
                    type="password"
                    id="password"
                    value={password}
                    onChange={e => {
                      setPasswordError("");
                      setPassword(e.target.value);
                    }}
                  />
                  <FormControlLabel
                    control={<Checkbox checked={remember} color="default" />}
                    label={t('Login.rememberInput')}
                    onChange={e => {
                      setRemember(!remember);
                    }}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    disableElevation
                    color="primary"
                    size="large"
                    className={classes.submit}
                    onClick={onLogin}
                  >
                    {t('Login.loginButton')}
                  </Button>
                  <Grid container>
                    <Grid item xs>
                      <Link href="#" variant="body2" onClick={e => { e.preventDefault(); setOpenForgotPassword(true) }}>
                        {t('Login.forgotPassword')}
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link href="/signup" variant="body2">
                        {t('Login.newAccount')}
                      </Link>
                    </Grid>
                  </Grid>
                </form>
              </div>
            </Container>
          </Paper>
        </Grid>
      </Grid>
      <ForgotPassword
        onClose={e => setOpenForgotPassword(false)}
        onSave={email => saveForgotPassword(email)}
        open={openForgotPassword}
      />
    </React.Fragment>
  );
}

export default Login;
