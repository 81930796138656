/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash';
import { useAuth } from 'context/auth';
import { useApp } from 'context/app';
import { api } from 'services/api';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import AddIcon from '@material-ui/icons/Add';
import AssessmentIcon from '@material-ui/icons/AssessmentSharp';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import SettingsIcon from '@material-ui/icons/Settings';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import AddAccount from 'pages/Modal/AddAccount';
import Logout from 'pages/Modal/Logout';

import { makeStyles, withStyles } from '@material-ui/core/styles';

const drawerWidth = 240;

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const useStyles = makeStyles(theme => ({
  drawer: {
    [theme.breakpoints.up('lg')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: 'none',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  closeMenuButton: {
    marginRight: 'auto',
    marginLeft: 0,
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  addAccountIcon: {
    color: theme.palette.primary.light,
    backgroundColor: theme.palette.primary.main
  }
}));

function AppBarLoggedIn() {

  const { setAuthToken, setLoginData, loginData } = useAuth();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useApp();
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const [ openDrawer, setOpenDrawer ] = useState(false);
  const [ openAvatarMenu, setOpenAvatarMenu ] = useState(null);
  const [ openLogoutDialog, setOpenLogoutDialog ] = useState(false);
  const [ selectedUser, setSelectedUser ] = useState({});

  const [ openAddAccount, setOpenAddAccount ] = useState(false);

  useEffect(() => {
    const selected = loginData.accounts.find(l => l.selected) || _.get(loginData, 'accounts[0]');
    if (selected) {
      setSelectedUser(selected);
    }
  }, [loginData]);

  const logOut = () => {
    setLoginData();
    setAuthToken(); 
  };

  const setLink = (path) => {
    if (location.pathname !== path) {
      history.push(path);
    }
  };

  const selectAccount = async (accountId) => {
    await api.put(`/account/${accountId}`, {
      selected: true
    });
    const cp = loginData;
    cp.accounts.forEach(l => {
      if (l._id === accountId) {
        l.selected = true;
      } else {
        l.selected = false;
      }
    });
    handleAvatarMenuClose();
    return setLoginData(Object.assign({}, cp));
  };

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const handleAvatarMenuOpen = (e) => {
    setOpenAvatarMenu(e.currentTarget);
  };

  const handleAvatarMenuClose = () => {
    setOpenAvatarMenu(null);
  };

  const handleLogoutDialogOpen = () => {
    setOpenLogoutDialog(true);
  };

  const handleLogoutDialogClose = () => {
    setOpenLogoutDialog(false);
  };

  const saveAddAccount = async (newAccount) => {
    newAccount = newAccount.replace('@', '');

    const instagramData = await api.get(`/user/instagram/${newAccount}`);
    const user = _.get(instagramData, 'data.graphql.user');

    if (!user || !user.id) {
      enqueueSnackbar(t('Signup.instagramNotFound'), {
        variant: 'error',
      });
      return false;
    }

    if (user.is_private) {
      enqueueSnackbar(t('Signup.instagramPrivate'), {
        variant: 'error',
      });
      return false;
    }

    const result = await api.post(`/account`, {
      instagramUsername: newAccount,
      avatar: user.profile_pic_url,
      instagramId: user.id,
    });

    if (result.data.error) {
      enqueueSnackbar(result.data.error, {
        variant: 'error',
      });
      setOpenAddAccount(false);
      return false;
    }
    
    const cp = loginData;
    cp.accounts.push(result.data);
    setLoginData(Object.assign({}, cp));
    setOpenAddAccount(false);
    selectAccount(result.data._id);
  };

  const drawer = (
    <React.Fragment>
      <div className={classes.toolbarIcon}>
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <List style={{ paddingTop: 0 }}>
        <ListItem button selected={location.pathname === '/app'} onClick={e => {          
          setLink("/app");
          handleDrawerClose();
        }}>
          <ListItemIcon>
            <DashboardIcon color="inherit" />
          </ListItemIcon>
          <ListItemText primary={t('AppBarLoggedIn.dashboard')} />
        </ListItem>
        <ListItem button selected={location.pathname === '/analytics'} onClick={e => {
          setLink("/analytics");
          handleDrawerClose();
        }}>
          <ListItemIcon>
            <AssessmentIcon color="inherit" />
          </ListItemIcon>
          <ListItemText primary={t('AppBarLoggedIn.analytics')} />
        </ListItem>
        <ListItem button selected={location.pathname === '/settings'} onClick={e => {
          setLink("/settings");
          handleDrawerClose();
        }}>
          <ListItemIcon>
            <SettingsIcon color="inherit" />
          </ListItemIcon>
          <ListItemText primary={t('AppBarLoggedIn.settings')} />
        </ListItem>
        <ListItem button selected={location.pathname === '/support'} onClick={e => {
          setLink("/support");
          handleDrawerClose();
        }}>
          <ListItemIcon>
            <LiveHelpIcon color="inherit" />
          </ListItemIcon>
          <ListItemText primary={t('AppBarLoggedIn.support')} />
        </ListItem>
        <ListItem button onClick={e => {
          handleLogoutDialogOpen();
          handleDrawerClose();
        }}>
          <ListItemIcon>
            <PowerSettingsNewIcon color="inherit" />
          </ListItemIcon>
          <ListItemText primary={t('AppBarLoggedIn.logout')} />
        </ListItem>
      </List>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <AppBar color="inherit" position="absolute" className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleDrawerOpen}
            className={classes.menuButton}>
            <MenuIcon />
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            linkna.bio
          </Typography>
          <React.Fragment>
            <List style={{ position: 'absolute', right: 10 }}>
              <ListItem button onClick={handleAvatarMenuOpen} aria-controls="customized-menu" aria-haspopup="true">
                <ListItemAvatar>
                  <Avatar
                    alt={'@' + selectedUser.instagramUsername}
                    src={selectedUser.avatar}
                  />
                </ListItemAvatar>
                <ListItemText primary={'@' + selectedUser.instagramUsername} />
                <KeyboardArrowDownIcon fontSize="small" />
              </ListItem>
            </List>
            <StyledMenu
              id="customized-menu"
              anchorEl={openAvatarMenu}
              keepMounted
              open={Boolean(openAvatarMenu)}
              getContentAnchorEl={null}
              onClose={handleAvatarMenuClose}
            >
              {loginData.accounts.filter(l => !l.selected || l.selected === false).map(l => {
                return (
                  <MenuItem key={l._id} onClick={() => selectAccount(l._id)}>
                    <ListItemAvatar>
                      <Avatar
                        alt={'@' + l.instagramUsername}
                        src={l.avatar}
                      />
                    </ListItemAvatar>
                    <ListItemText primary={'@' + l.instagramUsername} />
                  </MenuItem>
                );
              })}
              <MenuItem key="addAccount" onClick={() => {
                setOpenAddAccount(true);
                handleAvatarMenuClose();
              }}>
                <ListItemAvatar>
                  <Avatar
                    alt={t('AppBarLoggedIn.addNewAccount')}
                    className={classes.addAccountIcon}>
                    <AddIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={t('AppBarLoggedIn.addNewAccount')} />
              </MenuItem>
            </StyledMenu>
          </React.Fragment>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer}>
        <Hidden lgUp implementation="js">
          <Drawer
            variant="temporary"
            anchor={'left'}
            onClose={handleDrawerClose}
            classes={{
              paper: classes.drawerPaper,
            }}
            open={openDrawer}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden mdDown implementation="js">
          <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper
            }}
            open={openDrawer}
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <Logout
        onClick={e => logOut()}
        onClose={handleLogoutDialogClose}
        open={openLogoutDialog}
      />
      <AddAccount
        onClose={e => setOpenAddAccount(false)}
        onSave={(account) => saveAddAccount(account)}
        open={openAddAccount}
      />
    </React.Fragment>
  );
}

export default AppBarLoggedIn;