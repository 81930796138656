/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useTranslation } from 'react-i18next';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

function Support() {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12} md={6} style={{ marginBottom: 20 }}>
          <Container component="main">
            <Card>
              <CardHeader title={t('Support.title')} subheader={t('Support.subheader')} />
              <CardContent>
                <Typography component="p">Email: <a href="mailto:suporte@dlw.zendesk.com">suporte@dlw.zendesk.com</a></Typography>
              </CardContent>
            </Card>
          </Container>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Support;
