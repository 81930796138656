import axios from 'axios';

const api = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL,
});

const api2 = axios.create({});

api.interceptors.request.use(async config => {
  if (typeof config.headers['X-FullScreenLoader'] == 'undefined' || config.headers['X-FullScreenLoader'] === true) {
    document.getElementById('cover-spin').style.display = 'block';
  } else {
    delete config.headers['X-FullScreenLoader'];
  }
  
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

api.interceptors.response.use((response) => {
  document.getElementById('cover-spin').style.display = 'none';
  if (response.data.error && response.data.expired === true) {
    localStorage.removeItem('token');
    localStorage.removeItem('login');
    window.location.reload();
  }
  return response;
});

let tries = 0;
api2.interceptors.response.use(response => {
  tries = 0;
  return response;
}, error => {
  if (error && error.message === 'Network Error') {
    if (tries < 10) {
      tries++;
      return api2.request(error.config);
    }
  }
  return Promise.reject(error);
});

export {
  api,
  api2,
};