/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import AppBarLoggedOut from 'components/AppBarLoggedOut';
import AppBarUser from 'components/AppBarUser';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Footer from 'components/Footer';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import NotFound from 'pages/NotFound';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { api } from 'services/api';
import { addProtocol } from 'utils';
import Image from 'react-render-image';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles(theme => ({
  titleMenu: {
    fontFamily: 'Karla, Karla, sans-serif',
    letterSpacing: 0,
    textTransform: 'inherit',
    fontWeight: 700,
  },
  avatarMenu: {
    width: 96,
    height: 96,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    minHeight: '100vh',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  image: {
    width: '100%',
    padding: 5,
    '&:hover': {
      opacity: 0.9,
    }
  }, 
  linkTitle: {
    textDecoration: 'none !important',
  }
}));

function Signup(props) {
  const { t } = useTranslation();
  const classes = useStyles();

  const [ posts, setPosts ] = useState([]);
  const [ menus, setMenus ] = useState([]);
  const [ selectedUser, setSelectedUser ] = useState({});
  const [ loading, setLoading ] = useState(false);
  const [ notFound, setNotFound ] = useState(false);

  const onImageError = (err, post) => {
    const cp = posts;
    const index = cp.findIndex(pp => pp.id === post.id);
    cp.splice(index, 1);
    setPosts([...cp]);
  };

  const onLinkClick = async (link) => {
    await api.put(`/link/${link.id}/click`, {
      views: 1,
    }, { headers: { 'X-FullScreenLoader' : false } });
  };

  const onMenuClick = async (menu) => {
    await api.put(`/link-menu/${menu.id}/click`, {
      views: 1,
    }, { headers: { 'X-FullScreenLoader' : false } });
  };

  useEffect(() => {
    async function fetchAccount(username) {
      setLoading(true);
      const result = await api.get(`/account/${username}`);
      setLoading(false);
      if (result.data.error) {
        setNotFound(true);
      }
      setSelectedUser(_.omit(result.data, 'links'));
      if (result.data.accountType === 'post') {
        setPosts(result.data.links);
      } else {
        setMenus(result.data.menus);
      }
    };
    const username = props.match.params.username;
    if (username) {
      fetchAccount(username);
    }
  }, [props.match.params.username]);

  const getSelectedButtonColor = () => {
    let menuColor = selectedUser.menuColor ? selectedUser.menuColor : { r: 51, g: 51, b: 51, a: 1};
    if (!selectedUser.outlinedMenu && selectedUser.backgroundColor) {
      return `rgba(${selectedUser.backgroundColor.r}, ${selectedUser.backgroundColor.g}, ${selectedUser.backgroundColor.b}, ${selectedUser.backgroundColor.a})`;
    } else if (selectedUser.outlinedMenu) {
      return `rgba(${menuColor.r}, ${menuColor.g}, ${menuColor.b}, ${menuColor.a})`
    }
    return '';
  };

  const calcMinHeight = () => {
    if (selectedUser.accountType === 'post' && !posts.length) {
      return '';
    }
    if (selectedUser.accountType === 'menu' && !menus.length) {
      return '';
    }
    return '100vh';
  }

  const StyledButton = withStyles(() => {
    const menuColor = selectedUser.menuColor ? selectedUser.menuColor : { r: 51, g: 51, b: 51, a: 1};
    let style = {
      root: {
        borderRadius: 0,
        border: `2px solid rgba(${menuColor.r}, ${menuColor.g}, ${menuColor.b}, 1)`,
        minHeight: 56,
        padding: '15px 20px',
        fontSize: 16,
        letterSpacing: 0,
        textTransform: 'inherit',
        fontFamily: 'Karla, Karla, sans-serif',
        fontWeight: 700,
      },
    };
    if (selectedUser.outlinedMenu) {
      style.root['&:hover'] = {
        backgroundColor: `rgba(${menuColor.r}, ${menuColor.g}, ${menuColor.b}, 0.08)`
      };
    } else {
      style.root['&:hover'] = {
        backgroundColor: `transparent !important`,
        color: `rgba(${menuColor.r}, ${menuColor.g}, ${menuColor.b}, 1) !important`,
      };
    }
    return style;
  })(Button);

  const renderNotFound = () => {
    return (
      <React.Fragment>
        <AppBarLoggedOut />
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth="xl" className={classes.container}>
            <NotFound />
            <Footer />
          </Container>
        </main>
      </React.Fragment>
    );
  };

  const renderPublicAccountPage = () => {
    let menuColor = selectedUser.menuColor ? selectedUser.menuColor : { r: 51, g: 51, b: 51, a: 1 };
    return (
      <React.Fragment>
        {!loading && (selectedUser.accountType === 'post' || (selectedUser.accountType === 'menu' && !menus.length)) ? <AppBarUser user={selectedUser} /> : null}
        <main className={classes.content}>
          {selectedUser.accountType === 'post' || (selectedUser.accountType === 'menu' && !menus.length) ? <div className={classes.appBarSpacer} /> : null}
          <div>
            <Container className={classes.container} style={ selectedUser.accountType !== 'menu' ? {
              paddingBottom: 0,
              maxWidth: '100%',
            } : {
              minHeight: calcMinHeight(),
              maxWidth: '100%',
              paddingBottom: 0,
              backgroundImage: selectedUser.gradient === true ? `linear-gradient(
                transparent,
                rgba(
                  ${_.get(selectedUser, 'backgroundEndColor.r', 255)},
                  ${_.get(selectedUser, 'backgroundEndColor.g', 255)},
                  ${_.get(selectedUser, 'backgroundEndColor.b', 255)},
                  ${_.get(selectedUser, 'backgroundEndColor.a', 1)}
                )
              )` : 'none',
              backgroundColor: selectedUser.backgroundColor ? `rgba(${selectedUser.backgroundColor.r}, ${selectedUser.backgroundColor.g}, ${selectedUser.backgroundColor.b}, ${selectedUser.backgroundColor.a})` : '',
            }}>
              {posts.length ? (
                <Grid container style={{ minHeight: '80vh', }}>
                  {posts.map((post, index) => {
                    const link = addProtocol(post.link);
                    return (
                      <Grid key={index} item xs={12} sm={6} lg={3}>
                        <Link href={link || '#'} target="_blank" onClick={() => onLinkClick(post)}>
                          <Image
                            onError={(err) => onImageError(err, post)}
                            src={post.instagramImgUrl}
                            loaded={<img className={classes.image} src={post.instagramImgUrl} alt={post.instagramShortcode} />}
                            loading={<Box p={1}><Skeleton style={{ width: '100%', height: '320px', marginRight: 30 }} animation="wave" variant="rect" /></Box>} />
                        </Link>
                      </Grid>
                    );
                  })}
                </Grid>
              ) : null}

              {!posts.length && !loading && selectedUser.accountType === 'post' ? (
                <Grid container spacing={0} align="center" alignItems="center" justify="center" style={{ minHeight: '80vh' }}>
                  <Grid item xs={12}>
                    <Typography variant="h6">
                      {t('PublicAccountPage.noPosts')}
                    </Typography>
                    <Typography variant="body1">
                      {t('PublicAccountPage.noPosts2')}
                    </Typography>
                  </Grid>
                </Grid>
              ) : null}

              {menus.length ? (
                <Container style={{ maxWidth: 700 }}>
                  <Grid container spacing={0}>
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', }}>
                      <Link href={`//instagram.com/${selectedUser.instagramUsername}`} target="_blank">
                        <Avatar className={classes.avatarMenu} src={selectedUser.avatar} alt={selectedUser.instagramUsername} />
                      </Link>
                    </Grid>
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                      <Link className={classes.linkTitle} href={`//instagram.com/${selectedUser.instagramUsername}`} target="_blank">
                        <Typography className={classes.titleMenu} style={{ color: `rgba(${menuColor.r}, ${menuColor.g}, ${menuColor.b}, ${menuColor.a})` }} variant="h6" component="h1">@{selectedUser.instagramUsername}</Typography>
                      </Link>
                    </Grid>
                  </Grid>
                  <Grid container spacing={0} style={{ minHeight: '66vh', marginTop: 20 }}>
                    <Grid item xs={12} style={{ marginTop: 20 }}>
                      {menus.map((menu, index) => {
                        return (
                          <StyledButton
                            disableElevation
                            variant={selectedUser.outlinedMenu ? "outlined" : "contained"}
                            color="primary"
                            key={index}
                            style={{
                              backgroundColor: !selectedUser.outlinedMenu ? `rgba(${menuColor.r}, ${menuColor.g}, ${menuColor.b}, ${menuColor.a})` : '',
                              color: getSelectedButtonColor(),
                              border: selectedUser.outlinedMenu ? `2px solid rgba(${menuColor.r}, ${menuColor.g}, ${menuColor.b}, ${menuColor.a})` : '',
                              marginBottom: 20,
                            }}
                            fullWidth
                            href={addProtocol(menu.link)}
                            onClick={() => onMenuClick(menu)}
                          >
                            {menu.text}
                          </StyledButton>
                        )
                      })}
                    </Grid>
                  </Grid>
                </Container>
              ) : null}

              {!menus.length && !loading && selectedUser.accountType === 'menu' ? (
                <React.Fragment>
                  <Grid container spacing={0} align="center" alignItems="center" justify="center" style={{ minHeight: '80vh' }}>
                    <Grid item xs={12}>
                      <Typography variant="h6">
                        {t('PublicAccountPage.noMenus')}
                      </Typography>
                      <Typography variant="body1">
                        {t('PublicAccountPage.noMenus2')}
                      </Typography>
                    </Grid>
                  </Grid>
                </React.Fragment>
              ) : null}
              <div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', flexGrow: 1, }}>
                <Link className={classes.linkTitle} href={`//linkna.bio`} target="_blank">
                  <Typography className={classes.titleMenu} style={{ color: `rgba(${menuColor.r}, ${menuColor.g}, ${menuColor.b}, ${menuColor.a})` }} variant="h6" component="h1">linkna.bio</Typography>
                </Link>
              </div>
            </Container>
          </div>
        </main>
      </React.Fragment>
    );
  };

  return notFound ? renderNotFound() : renderPublicAccountPage();
}

export default Signup;
