import React from 'react';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  footer: {
    backgroundColor: "#F8F8F8",
    borderTop: "1px solid #E7E7E7",
    textAlign: "center",
    position: "fixed",
    left: "0",
    bottom: "0",
    paddingTop: "10px",
    width: "100%",
    zIndex: 1202,
  },
}));

function Footer(props) {
  const siteUrl = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_SITE_URL_PROD : process.env.REACT_APP_SITE_URL;
  const classes = useStyles();
  const style = {};
  style.height = props['small'] ? '30px' : '40px';
  return (
    <Box pt={1}>
      <div className={classes.footer} style={style}>
        <Typography variant="body2" align="center">
          {'© ' + new Date().getFullYear() + ' '}
          <Link color="inherit" href={siteUrl}>
            {siteUrl}
          </Link>
          {/* {' — '} */}
          {/* <Link>Termos de uso</Link> */}
        </Typography>
      </div>
    </Box>
  );
}

export default Footer;
